const getDevelopmentConfig = () => ({
  API_BASE_URL: "http://localhost:3000/api",
  isAnalyticsEnabled: false,
});

const getProducationConfig = () => ({
  API_BASE_URL: "https://svoecafe.by/api",
  isAnalyticsEnabled: true,
});

export const appConfig =
  process.env.NODE_ENV === "development"
    ? getDevelopmentConfig()
    : getProducationConfig();
