import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import getSagaInjectors from './getSagaInjectors';

/**
 * Create injector of saga
 * @name createSagaInjector
 * @function
 * @param {StoreEnvOptions} storeEnvOptions
 *
 */
export default ({ context }) =>
  /**
   * Dynamically injects a saga, passes component's props as saga arguments
   *
   * @function
   * @name injectSagas
   * @param {object} sagas - a key/value map of sagas
   *
   * `key` - a key of the saga
   * `value` - object like { saga, mode }
   * [saga] - a root saga that will be injected
   * [mode] - by default (constants.RESTART_ON_REMOUNT) the saga will be started on component mount and
   * cancelled with `task.cancel()` on component un-mount for improved performance. Another two options:
   *   - constants.DAEMON—starts the saga on component mount and never cancels it or starts again,
   *   - constants.ONCE_TILL_UNMOUNT—behaves like 'RESTART_ON_REMOUNT' but never runs it again.
   *
   */
  (sagas) => (WrappedComponent) => {
    class InjectSaga extends React.Component {
      static displayName = `withSaga(${
        WrappedComponent.displayName || WrappedComponent.name || 'Component'
      })`;

      static WrappedComponent = WrappedComponent;

      static contextType = context;

      injectors = getSagaInjectors(this.context.store);

      componentWillMount() {
        const { injectSagas: injectSagasInternal } = this.injectors;

        injectSagasInternal(sagas);
      }

      componentWillUnmount() {
        const { ejectSagas } = this.injectors;

        ejectSagas(sagas);
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    return hoistNonReactStatics(InjectSaga, WrappedComponent);
  };
