import React from "react";
import * as Styled from "../styled";
import successImage from "../images/success.png";

export const ThanksMailStep = () => (
  <>
    <img src={successImage} width={70} alt="готово" />
    <Styled.StepName>Cпасибо!</Styled.StepName>
    <Styled.StepDescription>
      Директор с вами свяжется в ближайшее время
    </Styled.StepDescription>
  </>
);
