class API {
  constructor() {
    this.backendUrl = "/";
    this.token = null;
  }

  init = ({ backendUrl }) => {
    this.backendUrl = backendUrl;
  };

  setToken = ({ token }) => {
    this.token = token;
  };

  getHeaders = () => {
    const headers = new Headers();

    if (this.token) {
      headers.set("Authorization", `Bearer ${this.token}`);
    }

    return headers;
  };

  getToken = () =>
    window
      .fetch(`${this.backendUrl}/init`, {
        headers: this.getHeaders(),
        method: "GET",
      })
      .then((res) => res.json());

  loadMenu = () =>
    window
      .fetch(`${this.backendUrl}/menu`, {
        headers: this.getHeaders(),
        method: "GET",
      })
      .then((res) => res.json());

  palceAnOrder = ({ data, products, params }) =>
    window
      .fetch(`${this.backendUrl}/place-order`, {
        headers: this.getHeaders(),
        method: "POST",
        body: JSON.stringify({ data, products, params }),
      })
      .then((res) => res.json());

  // getReviews = () =>
  //   window
  //     .fetch(`https://admin.svoecafe.by/api/prod/reviews`, {
  //       headers: this.getHeaders(),
  //       method: "GET",
  //     })
  //     .then((res) => res.json());

  sentReview = ({ data }) =>
    window
      .fetch(`${this.backendUrl}/review`, {
        headers: this.getHeaders(),
        method: "POST",
        body: JSON.stringify({ data }),
      })
      .then((res) => res.json());
}

const APIService = new API();

export { APIService };
