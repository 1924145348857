import styled from "styled-components";

export const Layout = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 0px 60px;
  box-sizing: border-box;

  @media (max-width: 960px) {
    padding: 0px 20px;
  }
`;
