import React from "react";
import * as Styled from "./styled";

export const ButtonWithActions = ({
  removeFromBasketHandler,
  countInBasket,
  addToBasketHandler,
  ...otherProps
}) => (
  <Styled.ButtonWithActions {...otherProps}>
    <Styled.ButtonMinus onClick={removeFromBasketHandler}>-</Styled.ButtonMinus>
    <Styled.CountInBasket>{countInBasket}</Styled.CountInBasket>
    <Styled.ButtonPlus onClick={addToBasketHandler}>+</Styled.ButtonPlus>
  </Styled.ButtonWithActions>
);
