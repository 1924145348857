import React, { useEffect } from "react";
import { connectToStore } from "store";
import { createStructuredSelector } from "reselect";
import { cityInfoSelector } from "containers/Application/selectors";
import { Layout } from "components/Layout";
import { TitleL1 } from "components/UI-Kit/Titles";
import contactsIcon from "./images/contacts.svg";
import addressIcon from "./images/address.svg";
import timeIcon from "./images/time.svg";
import * as Styled from "./styled";

export const ContactPageContainer = ({ cityInfo }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Layout>
        <Styled.Wrapper>
          <Styled.Block icon={contactsIcon}>
            <Styled.Name>Контакты</Styled.Name>
            <Styled.Content>
              <Styled.Phone href={`tel:${cityInfo.phone}`}>
                {cityInfo.phone_label}
              </Styled.Phone>
            </Styled.Content>
          </Styled.Block>
          <Styled.Block icon={addressIcon}>
            <Styled.Name>Адрес</Styled.Name>
            <Styled.Content>{cityInfo.address}</Styled.Content>
          </Styled.Block>
          <Styled.Block icon={timeIcon}>
            <Styled.Name>График работы</Styled.Name>
            <Styled.Content>11:00 - 23:00</Styled.Content>
          </Styled.Block>
        </Styled.Wrapper>
        <TitleL1>Где мы находимся</TitleL1>
      </Layout>
      <Styled.Map title="location" src={cityInfo.location_map} />
    </>
  );
};

export const ContactPage = connectToStore(
  createStructuredSelector({
    cityInfo: cityInfoSelector,
  }),
  null
)(ContactPageContainer);
