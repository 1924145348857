import React, { useEffect, useState } from "react";
import { connectToStore } from "store";
import {
  addToBasket as addToBasketAction,
  removeFromBasket as removeFromBasketAction,
  addModifierToProduct as addModifierToProductAction,
  removeModifierFromProduct as removeModifierFromProductAction,
} from "containers/Application/actions";
import { ModalDialog } from "components/ModalDialog";
import { ButtonWithActions } from "components/UI-Kit/Buttons/ButtonWithActions";
import * as Styled from "./styled";

const ProductCardContainer = ({
  product: productWithoutModification,
  isSushiCategory,
  productWithModification,
  addToBasket,
  removeFromBasket,
  isPizzaCategory,
  addModifierToProduct,
  removeModifierFromProduct,
}) => {
  const [product, setProduct] = useState(
    productWithoutModification || productWithModification[0]
  );

  const [isOpenImage, setIsOpenImage] = useState(false);

  // Обновить корзину
  useEffect(() => {
    const activeProduct =
      productWithoutModification ||
      productWithModification.find(({ id }) => id === product.id);

    if (product.basket !== activeProduct.basket) {
      setProduct(activeProduct);
    }
  }, [
    productWithoutModification,
    productWithModification,
    product.basket,
    product.id,
  ]);

  const getOut = () => {
    const outValue = Math.ceil(product.weight * 1000);

    if (outValue === 0) {
      return null;
    }

    if (isSushiCategory) {
      return <Styled.Out>{outValue} г. | 8 шт.</Styled.Out>;
    }

    return <Styled.Out>{outValue} г.</Styled.Out>;
  };

  const getPrice = () => {
    return product.sizePrices[0].price.currentPrice.toFixed(2);
  };

  const changeModification = (e) => {
    const value = e.target.value;

    setProduct(
      productWithModification.find(
        ({ modification, doughtType }) =>
          modification === value && doughtType === product.doughtType
      )
    );
  };

  const changeDoughtType = (e) => {
    const value = e.target.value;

    setProduct(
      productWithModification.find(
        ({ doughtType, modification }) =>
          doughtType === value && modification === product.modification
      )
    );
  };

  const renderModifications = () => {
    if (productWithModification) {
      return (
        <Styled.Modification
          value={product.modification}
          onChange={changeModification}
        >
          {productWithModification
            .filter((el) => el.doughtType === product.doughtType)
            .map(({ modification }) => (
              <option value={modification}>{modification}</option>
            ))}
        </Styled.Modification>
      );
    }

    return <div />;
  };

  const renderDoughtType = () => {
    if (!isPizzaCategory) {
      return null;
    }

    if (productWithModification) {
      const array = productWithModification.filter(
        (el) => el.modification === product.modification
      );

      if (array.length === 1) {
        return null;
      }

      return (
        <Styled.DoughtType
          value={product.doughtType}
          onChange={changeDoughtType}
        >
          {array.map(({ doughtType }) => (
            <option value={doughtType}>{doughtType}</option>
          ))}
        </Styled.DoughtType>
      );
    }

    return <div />;
  };

  const renderCheeseBoard = () => {
    if (!isPizzaCategory) {
      return null;
    }

    return <Styled.CheeseBoard >Сырный борт</Styled.CheeseBoard>
  };

  const renderDescription = () => {
    return <Styled.Description>{product.description}</Styled.Description>;
  };

  const renderButton = () => {
    const countInBasket = product.basket;

    if (countInBasket === 0) {
      return (
        <Styled.Button onClick={addToBasketHandler}>В корзину</Styled.Button>
      );
    } else {
      return (
        <ButtonWithActions
          removeFromBasketHandler={removeFromBasketHandler}
          addToBasketHandler={addToBasketHandler}
          countInBasket={countInBasket}
        />
      );
    }
  };

  const addToBasketHandler = () => {
    addToBasket({ productId: product.id });
  };

  const addModifierToProductHandler = () => {
    addModifierToProduct({ productId: product.id });
  };

  const removeModifierFromProductHandler = () => {
    removeModifierFromProduct({ productId: product.id });
  };

  const removeFromBasketHandler = () => {
    removeFromBasket({ productId: product.id });
  };

  const openImage = () => {
    setIsOpenImage(true);
  };

  return (
    <>
      {isOpenImage && (
        <ModalDialog onClose={() => setIsOpenImage(false)} large>
          <Styled.BigImage src={product.imageLinks[0]} />
        </ModalDialog>
      )}
      <Styled.CardWrapper>
        <Styled.ProductCard>
          <Styled.Image src={product.imageLinks[0]} onClick={openImage} />
          <Styled.Wrapper>
            <div>
              <Styled.Name>{product.name}</Styled.Name>
              {renderDescription()}
            </div>
            <div>
              <Styled.PriceAndOut>
                <Styled.Price>
                  {getPrice()} <span>руб.</span>
                </Styled.Price>
                {getOut()}
              </Styled.PriceAndOut>
              {/* {renderCheeseBoard()} */}
              {renderDoughtType()}
              <Styled.Bottom>
                {renderModifications()}
                {renderButton()}
              </Styled.Bottom>
            </div>
          </Styled.Wrapper>
        </Styled.ProductCard>
      </Styled.CardWrapper>
    </>
  );
};

export const ProductCard = connectToStore(null, {
  addToBasket: addToBasketAction,
  removeFromBasket: removeFromBasketAction,
  addModifierToProduct: addModifierToProductAction,
  removeModifierFromProduct: removeModifierFromProductAction,
})(ProductCardContainer);
