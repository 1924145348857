import { Record, fromJS } from "immutable";
import { appConfig } from "appConfig";
import {
  USER_PROPERTY_ID,
  PAY_TYPE_OPTIONS,
  PAY_TYPES,
  DELIVERY_TYPES,
} from "./constants";
import * as ValidatorRules from "./validators";

const defaultState = {
  isInitialized: false,
  isLoading: false,
  isReviewQuizOpen: false,
  appConfig,
  cityInfo: fromJS({
    id: "",
    name: "",
  }),
  menu: fromJS({
    categories: [],
    products: [],
    categoryIds: {},
  }),
  user: fromJS({
    [USER_PROPERTY_ID.NAME]: {
      id: USER_PROPERTY_ID.NAME,
      value: "",
      validators: [
        ValidatorRules.required("Введите ваше имя"),
        ValidatorRules.maxLength64(),
      ],
      error: "",
    },
    [USER_PROPERTY_ID.PHONE]: {
      id: USER_PROPERTY_ID.PHONE,
      value: "",
      validators: [ValidatorRules.required("Введите ваш номер телефона")],
      error: "",
    },
    [USER_PROPERTY_ID.FLOOR]: {
      id: USER_PROPERTY_ID.FLOOR,
      value: "",
      validators: [ValidatorRules.maxLength64()],
      error: "",
    },
    [USER_PROPERTY_ID.STREET]: {
      id: USER_PROPERTY_ID.STREET,
      value: "",
      validators: [
        ValidatorRules.required("Введите улицу"),
        ValidatorRules.maxLength64(),
      ],
      error: "",
    },
    [USER_PROPERTY_ID.PAY_TYPE]: {
      id: USER_PROPERTY_ID.PAY_TYPE,
      value: PAY_TYPES.CARD,
      validators: [],
      error: "",
      options: PAY_TYPE_OPTIONS,
    },
    [USER_PROPERTY_ID.BANKNOTE]: {
      id: USER_PROPERTY_ID.BANKNOTE,
      value: "",
      validators: [ValidatorRules.maxLength64()],
      error: "",
    },
    [USER_PROPERTY_ID.DELIVERY_TYPE]: {
      id: USER_PROPERTY_ID.DELIVERY_TYPE,
      value: DELIVERY_TYPES.SELF,
      validators: [],
      error: "",
    },
    [USER_PROPERTY_ID.HOUSE_NUMBER]: {
      id: USER_PROPERTY_ID.HOUSE_NUMBER,
      value: "",
      validators: [
        ValidatorRules.required("Введите номер дома"),
        ValidatorRules.maxLength64(),
      ],
      error: "",
    },
    [USER_PROPERTY_ID.FLOOR]: {
      id: USER_PROPERTY_ID.FLOOR,
      value: "",
      validators: [ValidatorRules.maxLength64()],
      error: "",
    },
    [USER_PROPERTY_ID.FLAT_NUMBER]: {
      id: USER_PROPERTY_ID.FLAT_NUMBER,
      value: "",
      validators: [ValidatorRules.maxLength64()],
      error: "",
    },
    [USER_PROPERTY_ID.ENTRANCE]: {
      id: USER_PROPERTY_ID.ENTRANCE,
      value: "",
      validators: [ValidatorRules.maxLength64()],
      error: "",
    },
    [USER_PROPERTY_ID.COMMENT]: {
      id: USER_PROPERTY_ID.COMMENT,
      value: "",
      validators: [
        ValidatorRules.maxLength(
          200,
          "Максимально колличество допустимых символов 200"
        ),
      ],
      error: "",
    },
    [USER_PROPERTY_ID.REVIEW]: {
      id: USER_PROPERTY_ID.REVIEW,
      value: "",
      validators: [
        ValidatorRules.maxLength(
          1000,
          "Максимально колличество допустимых символов 1000"
        ),
      ],
      error: "",
    },
  }),
};

export class ApplicationModel extends Record(defaultState) {
  setIsInitialized(isInitialized) {
    return this.set("isInitialized", isInitialized);
  }

  setCityInfo({ cityInfo }) {
    return this.set("cityInfo", fromJS(cityInfo));
  }

  setMenu(menu) {
    return this.set("menu", fromJS(menu));
  }

  addToBasket({ productId }) {
    const products = this.getIn(["menu", "products"]).toJS();

    const nextProducts = products.map((product) => {
      if (product.id === productId) {
        product.basket += 1;
      }

      return product;
    });

    return this.setIn(["menu", "products"], fromJS(nextProducts));
  }

  removeFromBasket({ productId }) {
    const products = this.getIn(["menu", "products"]).toJS();

    const nextProducts = products.map((product) => {
      if (product.id === productId && productId.basket !== 0) {
        product.basket = product.basket - 1;
      }

      return product;
    });

    return this.setIn(["menu", "products"], fromJS(nextProducts));
  }

  totalRemoveFromBasket({ productId }) {
    const products = this.getIn(["menu", "products"]).toJS();

    const nextProducts = products.map((product) => {
      if (product.id === productId) {
        product.basket = 0;
      }

      return product;
    });

    return this.setIn(["menu", "products"], fromJS(nextProducts));
  }

  setUserProperty({ id, value }) {
    return this.setIn(["user", id, "value"], fromJS(value));
  }

  setLoading(value) {
    return this.set("isLoading", value);
  }

  validateProperties(propertiesForValidation) {
    const user = this.get("user").toJS();

    propertiesForValidation.forEach((propertyId) => {
      const property = user[propertyId];
      let error = "";

      property.validators.forEach((validator) => {
        const { isValid, message } = validator(property.value);

        if (!isValid) {
          error = message;
          return;
        }
      });

      property.error = error;
    });

    return this.set("user", fromJS(user));
  }

  openReviewQuiz(value) {
    return this.set("isReviewQuizOpen", true);
  }

  closeReviewQuiz(value) {
    return this.set("isReviewQuizOpen", false);
  }

  addModifierToProduct({ productId, modifierId }) {
    const products = this.getIn(["menu", "products"]).toJS();

    const nextProducts = products.map((product) => {
      if (product.id === productId) {
        product.modifiers.push({
          productId: modifierId,
          amount: 1,
        })
      }

      return product;
    });

    return this.setIn(["menu", "products"], fromJS(nextProducts));
  }

  removeModifierFromProduct({ productId, modifierId }) {
    const products = this.getIn(["menu", "products"]).toJS();

    const nextProducts = products.map((product) => {
      if (product.id === productId && productId.basket !== 0) {
        // Удалять только нужный модификатор
        product.modifiers = [];
      }

      return product;
    });

    return this.setIn(["menu", "products"], fromJS(nextProducts));
  }
}
