import styled, { css } from "styled-components";
import star from "../images/star.png";
import closeImage from "./images/close.svg";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Form = styled.div`
  width: 70%;
  max-width: 800px;
  background: #fff;
  box-shadow: 0px 4px 14px rgba(123, 122, 122, 0.1);
  border-radius: 30px;
  padding: 30px 40px 30px;
  box-sizing: border-box;

  @media (max-width: 600px) {
    width: calc(100% - 40px);
    padding: 25px 25px 20px;
  }
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 4px;
  position: relative;
  background: rgb(239, 239, 239);
`;

export const ProgressBarValue = styled.span`
  transform: scaleX(${({ value }) => value});
  position: absolute;
  transform-origin: left center;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.5s;
  background: rgb(249, 198, 70);
  inset: 0px;
`;

export const StepNumber = styled.div`
  text-align: right;
  color: rgb(150, 150, 150);
  font-size: 14px;
  margin-top: 6px;
`;

export const StepName = styled.div`
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 30px;
  margin-top: 20px;
  text-align: center;
  max-width: 360px;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

export const StepContent = styled.div`
  height: 100%;
  min-height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ButtonsWrapper = styled.div`
  justify-content: center;
  display: flex;
  gap: 15px;
  margin-top: 10px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Button = styled.button`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.8);
  background: #f9c646;
  padding: 13px 17px;
  border-radius: 15px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease 0s;

  &:active {
    transform: translateY(1px);
  }

  &:hover {
    background-color: #ebb42c;
    box-shadow: 0px 4px 14px rgba(123, 122, 122, 0.1);
  }

  ${({ reverse }) =>
    reverse &&
    css`
      background: #fff;
      border: 1px solid #f9c646;

      &:hover {
        border-color: #ebb42c;
        background: #fff;
        box-shadow: 0px 4px 14px rgba(123, 122, 122, 0.1);
      }
    `};

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

export const ReviewPlace = styled.a`
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  margin-bottom: 6px;
  padding: 10px 10px;
  max-width: 250px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  text-decoration: none;
  color: #000;

  &:hover {
    box-shadow: 0px 4px 14px rgba(123, 122, 122, 0.1);
  }

  img {
    width: 18px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    justify-content: center;
  }
`;

export const StepDescription = styled.div`
  font-size: 14px;
  max-width: 500px;
  text-align: center;
  margin-top: -20px;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.5);
`;

export const StarOpacity = styled.div`
  width: ${(props) => (5 - props.countOfStars) * 48}px;
  right: 0;
  position: absolute;
  height: 29px;
  opacity: 0.6;
  background: #fff;
  pointer-events: none;
`;

export const Star = styled.div`
  background: url(${star});
  width: 28px;
  height: 28px;
  background-size: contain;
  cursor: pointer;
  padding: 0px 10px;
  background-repeat: no-repeat;
  background-position: center;
`;

export const StarWrapper = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  margin-bottom: 25px;

  ${Star}:nth-child(1) {
    &:hover ~ ${StarOpacity} {
      width: 194px;
    }
  }

  ${Star}:nth-child(2) {
    &:hover ~ ${StarOpacity} {
      width: 150px;
    }
  }

  ${Star}:nth-child(3) {
    &:hover ~ ${StarOpacity} {
      width: 95px;
    }
  }

  ${Star}:nth-child(4) {
    &:hover ~ ${StarOpacity} {
      width: 45px;
    }
  }

  ${Star}:nth-child(5) {
    &:hover ~ ${StarOpacity} {
      width: 0px;
    }
  }
`;

export const FieldsWrapper = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const FormWrapper = styled.div`
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const TopPanel = styled.div`
  display: flex;
  justify-content: space-between;
  opacity: 0.6;
  margin-bottom: 17px;
`;

export const Back = styled.div`
  font-weight: 600;
  cursor: pointer;
`;

export const Close = styled.div`
  width: 17px;
  height: 17px;
  background: url(${closeImage});
  cursor: pointer;
`;
