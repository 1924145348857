import { createSelector } from "reselect";
import { STORE_KEY, USER_PROPERTY_ID, DELIVERY_TYPES } from "./constants";

export const applicationModelSelector = (state) => state.get(STORE_KEY);

export const isApplicationInitializedSelector = createSelector(
  applicationModelSelector,
  (applicationModel) => applicationModel.get("isInitialized")
);

export const applicationConfigSelector = createSelector(
  applicationModelSelector,
  (applicationModel) => applicationModel.get("appConfig")
);

export const isLoadingSelector = createSelector(
  applicationModelSelector,
  (applicationModel) => applicationModel.get("isLoading")
);

export const isReviewQuizOpenSelector = createSelector(
  applicationModelSelector,
  (applicationModel) => applicationModel.get("isReviewQuizOpen")
);

export const cityInfoSelector = createSelector(
  applicationModelSelector,
  (applicationModel) => applicationModel.get("cityInfo").toJS()
);

export const categoriesSelector = createSelector(
  applicationModelSelector,
  (applicationModel) => {
    const categories = applicationModel.getIn(["menu", "categories"]).toJS();

    return categories.sort((c1, c2) => c1.order - c2.order);
  }
);

export const productsSelector = createSelector(
  applicationModelSelector,
  (applicationModel) => applicationModel.getIn(["menu", "products"]).toJS()
);

export const categoryIdsSelector = createSelector(
  applicationModelSelector,
  (applicationModel) => applicationModel.getIn(["menu", "categoryIds"])
);

export const userSelector = createSelector(
  applicationModelSelector,
  (applicationModel) => applicationModel.get("user").toJS()
);

export const userNameSelector = createSelector(
  userSelector,
  (user) => user[USER_PROPERTY_ID.NAME]
);

export const userPhoneSelector = createSelector(
  userSelector,
  (user) => user[USER_PROPERTY_ID.PHONE]
);

export const userPayTypeSelector = createSelector(
  userSelector,
  (user) => user[USER_PROPERTY_ID.PAY_TYPE]
);

export const userCommentSelector = createSelector(
  userSelector,
  (user) => user[USER_PROPERTY_ID.COMMENT]
);

export const userStreetSelector = createSelector(
  userSelector,
  (user) => user[USER_PROPERTY_ID.STREET]
);

export const userHouseNumberSelector = createSelector(
  userSelector,
  (user) => user[USER_PROPERTY_ID.HOUSE_NUMBER]
);

export const userFlatNumberSelector = createSelector(
  userSelector,
  (user) => user[USER_PROPERTY_ID.FLAT_NUMBER]
);

export const userFloorSelector = createSelector(
  userSelector,
  (user) => user[USER_PROPERTY_ID.FLOOR]
);

export const userEntranceSelector = createSelector(
  userSelector,
  (user) => user[USER_PROPERTY_ID.ENTRANCE]
);

export const userDeliveryTypeSelector = createSelector(
  userSelector,
  (user) => user[USER_PROPERTY_ID.DELIVERY_TYPE]
);

export const userReviewSelector = createSelector(
  userSelector,
  (user) => user[USER_PROPERTY_ID.REVIEW]
);

export const userBanknoteSelector = createSelector(
  userSelector,
  (user) => user[USER_PROPERTY_ID.BANKNOTE]
);

export const productsInBasketSelector = createSelector(
  productsSelector,
  (products) => {
    const productsInBasket = products.filter(({ basket }) => basket !== 0);

    return productsInBasket.reduce((acc, item) => {
      if (acc.find(({ id }) => id === item.id)) {
        return acc; // если значение уже есть, то просто возвращаем аккумулятор
      }
      return [...acc, item]; // добавляем к аккумулятору и возвращаем новый аккумулятор
    }, []);
  }
);

export const totalPriceInBasketSelector = createSelector(
  productsInBasketSelector,
  (productsInBasket) =>
    productsInBasket
      .reduce(
        (acc, product) =>
          (acc += product.sizePrices[0].price.currentPrice * product.basket),
        0
      )
      .toFixed(2)
);

export const deliveryPriceSelector = createSelector(
  totalPriceInBasketSelector,
  cityInfoSelector,
  (totalPriceInBasket, cityInfo) =>
    (totalPriceInBasket < cityInfo.free_delivery_from ? 4 : 0).toFixed(2)
);

export const totalSumToPaySelector = createSelector(
  totalPriceInBasketSelector,
  deliveryPriceSelector,
  userDeliveryTypeSelector,
  (totalPriceInBasket, deliveryPrice, userDeliveryType) => {
    if (userDeliveryType.value === DELIVERY_TYPES.DELIVERY) {
      return (Number(totalPriceInBasket) + Number(deliveryPrice)).toFixed(2);
    }

    return totalPriceInBasket;
  }
);

export const makeSelectIsValidProperties = (propertyIds) =>
  createSelector(
    applicationModelSelector,
    (applicationModel) =>
      !propertyIds.some((propertyId) =>
        applicationModel.getIn(["user", propertyId, "error"])
      )
  );
