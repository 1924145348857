export const EVENTS = {
  ORDER_SUCCESS: "order_success",
  ORDER_FAILURE: "order_failure",
};

export const ANALYTICS_OPTIONS = {
  ORDER_VALUE: "order_valie",

};

class AnalyticsClass {
  constructor() {
    this.isEnabled = false;
    this.city = '';
  }

  init = ({ isEnabled, city }) => {
    this.isEnabled = isEnabled;
    this.city = city;
  };

  track = ({ event, options = {} }) => {
    if (this.isEnabled && window.gtag) {
      window.gtag("event", event, {
        city: this.city,
        ...options,
      });
    } else {
      console.info(event, {
        city: this.city,
        ...options,
      });
    }
  };
}

const AnalyticsService = new AnalyticsClass();

export { AnalyticsService };
