import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connectToStore } from "store";
import { createStructuredSelector } from "reselect";
import {
  isLoadingSelector,
  cityInfoSelector,
} from "containers/Application/selectors";
import {
  setCityInfo as setCityInfoAction,
  pushNextUrl as pushNextUrlAction,
} from "containers/Application/actions";
import { CITIES } from "containers/Application/constants";
import { Layout } from "components/Layout";
import { Progress } from "components/Progress";
import { URLS } from "appConstants";
import * as Styled from "./styled";
import { ChooseCityDialog } from "components/ChooseCityDialog";

export const HeaderContainer = ({
  pushNextUrl,
  isLoading,
  cityInfo,
  setCityInfo,
}) => {
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const [currentUrl, setCurrentUrl] = useState();

  const location = useLocation();

  useEffect(() => {
    setCurrentUrl("/" + location.pathname.split("/").pop());
  }, [location.pathname]);

  const openReviews = (e) => {
    e.preventDefault();
    pushNextUrl(`/${cityInfo.id}${URLS.REVIEWS}`);
    setIsOpenMobileMenu(false);
  };

  const openContacts = (e) => {
    e.preventDefault();
    pushNextUrl(`/${cityInfo.id}${URLS.CONTACTS}`);
    setIsOpenMobileMenu(false);
  };

  const openDelivery = (e) => {
    e.preventDefault();
    pushNextUrl(`/${cityInfo.id}${URLS.DELIVERY}`);
    setIsOpenMobileMenu(false);
  };

  const openMain = (e) => {
    e.preventDefault();
    pushNextUrl(`/${cityInfo.id}${URLS.MAIN}`);
    setIsOpenMobileMenu(false);
  };

  const isLinkActive = (url) => currentUrl === url;

  const changeCity = (event) => {
    const nextCityInfo = CITIES.find(({ id }) => id === event.target.value);

    const currentUrl = window.location.pathname.split("/").pop();
    pushNextUrl(`/${nextCityInfo.id}/${currentUrl}`);

    setCityInfo({ cityInfo: nextCityInfo });
  };

  const renderChooseCity = () => (
    <Styled.ChooseCityWrapper>
      <Styled.Point />
      <Styled.ChooseCity onChange={changeCity} value={cityInfo.id}>
        {CITIES.map((city) => (
          <option value={city.id}>{city.name}</option>
        ))}
      </Styled.ChooseCity>
    </Styled.ChooseCityWrapper>
  );

  return (
    <>
      <ChooseCityDialog cityInfo={cityInfo} changeCity={changeCity} />
      <Styled.Header>
        <Layout>
          <Styled.Flex>
            <Styled.Hamburger
              onClick={() => setIsOpenMobileMenu(!isOpenMobileMenu)}
              isOpenMobileMenu={isOpenMobileMenu}
            />
            <Styled.Logo href={URLS.MAIN} onClick={openMain} />
            <Styled.Menu isOpenMobileMenu={isOpenMobileMenu}>
              <Styled.Link
                href={URLS.MAIN}
                onClick={openMain}
                isActive={isLinkActive(URLS.MAIN)}
              >
                Меню
              </Styled.Link>
              <Styled.Link
                href={URLS.DELIVERY}
                onClick={openDelivery}
                isActive={isLinkActive(URLS.DELIVERY)}
              >
                Доставка и оплата
              </Styled.Link>
              <Styled.Link
                href={URLS.CONTACTS}
                onClick={openContacts}
                isActive={isLinkActive(URLS.CONTACTS)}
              >
                Контакты
              </Styled.Link>
              <Styled.Link
                href={URLS.REVIEWS}
                onClick={openReviews}
                isActive={isLinkActive(URLS.REVIEWS)}
              >
                Отзывы
              </Styled.Link>
              <Styled.MobileCity>{renderChooseCity()}</Styled.MobileCity>
            </Styled.Menu>
            <Styled.DesktopWrapper>
              <Styled.Flex>
                {renderChooseCity()}
                <Styled.Phone href={`tel:${cityInfo.phone}`}>
                  {cityInfo.phone_label}
                </Styled.Phone>
              </Styled.Flex>
            </Styled.DesktopWrapper>
            <Styled.MobilePhone href={`tel:${cityInfo.phone}`} />
          </Styled.Flex>
        </Layout>
        {isLoading && <Progress />}
      </Styled.Header>
    </>
  );
};

export const Header = connectToStore(
  createStructuredSelector({
    isLoading: isLoadingSelector,
    cityInfo: cityInfoSelector,
  }),
  {
    setCityInfo: setCityInfoAction,
    pushNextUrl: pushNextUrlAction,
  }
)(HeaderContainer);
