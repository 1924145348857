import React, { useEffect } from "react";
import { connectToStore } from "store";
import { createStructuredSelector } from "reselect";
import {
  totalPriceInBasketSelector,
  userNameSelector,
  userPhoneSelector,
  userPayTypeSelector,
  userCommentSelector,
  userStreetSelector,
  userHouseNumberSelector,
  userFlatNumberSelector,
  userFloorSelector,
  userEntranceSelector,
  userDeliveryTypeSelector,
  userBanknoteSelector,
  deliveryPriceSelector,
  cityInfoSelector,
  totalSumToPaySelector,
} from "containers/Application/selectors";
import {
  setUserProperty as setUserPropertyAction,
  palceAnOrder as palceAnOrderAction,
} from "containers/Application/actions";
import { DELIVERY_TYPES, PAY_TYPES } from "containers/Application/constants";
import { TextField } from "components/UI-Kit/TextField";
import { RadioField } from "components/UI-Kit/RadioField";
import { Layout } from "components/Layout";
import { TitleL1 } from "components/UI-Kit/Titles";
import * as Styled from "./styled";

const CheckoutPageContainer = ({
  userName,
  userPhone,
  userComment,
  setUserProperty,
  userDeliveryType,
  userStreet,
  userHouseNumber,
  userFlatNumber,
  userFloor,
  userEntrance,
  userPayType,
  userBanknote,
  totalPriceInBasket,
  palceAnOrder,
  deliveryPrice,
  cityInfo,
  totalSumToPay,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Styled.Checkout>
      <Layout>
        {totalPriceInBasket === "0.00" ? (
          <Styled.EmptyBasket>
            Вы не можете оформить заказ пока ваша корзина пуста.
          </Styled.EmptyBasket>
        ) : (
          <>
            <TitleL1>Оформление заказа</TitleL1>
            <Styled.Wrapper>
              <Styled.Block>
                <Styled.BlockName>1. Личные данные</Styled.BlockName>
                <Styled.Flex>
                  <TextField
                    value={userName.value}
                    setValue={(value) =>
                      setUserProperty({ id: userName.id, value })
                    }
                    placeholder="Имя*"
                    error={userName.error}
                  />
                  <TextField
                    value={userPhone.value}
                    setValue={(value) =>
                      setUserProperty({ id: userPhone.id, value })
                    }
                    placeholder="Номер телефона*"
                    error={userPhone.error}
                    isPhoneField
                  />
                </Styled.Flex>
              </Styled.Block>
              <Styled.Block>
                <Styled.BlockName>2. Адрес</Styled.BlockName>
                <Styled.DeliveryTopWrapper>
                  <Styled.DeliveryType
                    isActive={userDeliveryType.value === DELIVERY_TYPES.SELF}
                    onClick={() =>
                      setUserProperty({
                        id: userDeliveryType.id,
                        value: DELIVERY_TYPES.SELF,
                      })
                    }
                  >
                    Самовывоз
                  </Styled.DeliveryType>
                  <Styled.DeliveryType
                    isActive={
                      userDeliveryType.value === DELIVERY_TYPES.DELIVERY
                    }
                    onClick={() =>
                      setUserProperty({
                        id: userDeliveryType.id,
                        value: DELIVERY_TYPES.DELIVERY,
                      })
                    }
                  >
                    Доставка
                    <Styled.City>{cityInfo.name}</Styled.City>
                  </Styled.DeliveryType>
                </Styled.DeliveryTopWrapper>
                {userDeliveryType.value === DELIVERY_TYPES.SELF ? (
                  <Styled.DeliveryContent>
                    {cityInfo.address}
                  </Styled.DeliveryContent>
                ) : (
                  <Styled.DeliveryContent>
                    <Styled.Flex>
                      <TextField
                        value={userStreet.value}
                        setValue={(value) =>
                          setUserProperty({ id: userStreet.id, value })
                        }
                        placeholder="Улица*"
                        error={userStreet.error}
                      />
                      <TextField
                        value={userHouseNumber.value}
                        setValue={(value) =>
                          setUserProperty({ id: userHouseNumber.id, value })
                        }
                        placeholder="Дом*"
                        error={userHouseNumber.error}
                      />
                    </Styled.Flex>
                    <Styled.Flex>
                      <TextField
                        value={userFlatNumber.value}
                        setValue={(value) =>
                          setUserProperty({ id: userFlatNumber.id, value })
                        }
                        placeholder="Квартира"
                        error={userFlatNumber.error}
                      />
                      <TextField
                        value={userFloor.value}
                        setValue={(value) =>
                          setUserProperty({ id: userFloor.id, value })
                        }
                        placeholder="Этаж"
                        error={userFloor.error}
                      />
                      <TextField
                        value={userEntrance.value}
                        setValue={(value) =>
                          setUserProperty({ id: userEntrance.id, value })
                        }
                        placeholder="Подъезд"
                        error={userEntrance.error}
                      />
                    </Styled.Flex>
                  </Styled.DeliveryContent>
                )}
              </Styled.Block>
              <Styled.Block>
                <Styled.BlockName>3. Оплата</Styled.BlockName>
                <RadioField
                  setOption={(value) =>
                    setUserProperty({ id: userPayType.id, value })
                  }
                  name={userPayType.id}
                  options={userPayType.options}
                  value={userPayType.value}
                />

                {userPayType.value === PAY_TYPES.CASH && (
                  <TextField
                    value={userBanknote.value}
                    setValue={(value) =>
                      setUserProperty({ id: userBanknote.id, value })
                    }
                    placeholder="Сдача с"
                    error={userBanknote.error}
                  />
                )}
              </Styled.Block>
              <Styled.Block>
                <Styled.BlockName>4. Комментарий</Styled.BlockName>
                <TextField
                  value={userComment.value}
                  setValue={(value) =>
                    setUserProperty({ id: userComment.id, value })
                  }
                  placeholder="Комментарий"
                  error={userComment.error}
                  isTextArea
                />
              </Styled.Block>
            </Styled.Wrapper>
            <Styled.TotalWrapper>
              <Styled.Total>
                <div>Сумма заказа :</div>
                <div>{totalPriceInBasket}</div>
              </Styled.Total>
              {userDeliveryType.value === DELIVERY_TYPES.DELIVERY && (
                <Styled.Total>
                  <div>Стоимость доставки :</div>
                  <div>{deliveryPrice}</div>
                </Styled.Total>
              )}
              <Styled.Total>
                <div>К оплате :</div>
                <div>{totalSumToPay}</div>
              </Styled.Total>
              <Styled.Next onClick={palceAnOrder}>Оформить</Styled.Next>
            </Styled.TotalWrapper>
          </>
        )}
      </Layout>
    </Styled.Checkout>
  );
};

export const CheckoutPage = connectToStore(
  createStructuredSelector({
    userName: userNameSelector,
    userPhone: userPhoneSelector,
    userComment: userCommentSelector,
    userDeliveryType: userDeliveryTypeSelector,
    userStreet: userStreetSelector,
    userHouseNumber: userHouseNumberSelector,
    userFlatNumber: userFlatNumberSelector,
    userFloor: userFloorSelector,
    userEntrance: userEntranceSelector,
    userPayType: userPayTypeSelector,
    userBanknote: userBanknoteSelector,
    totalPriceInBasket: totalPriceInBasketSelector,
    deliveryPrice: deliveryPriceSelector,
    cityInfo: cityInfoSelector,
    totalSumToPay: totalSumToPaySelector,
  }),
  {
    setUserProperty: setUserPropertyAction,
    palceAnOrder: palceAnOrderAction,
  }
)(CheckoutPageContainer);
