import styled, { css } from "styled-components";

export const Checkout = styled.div`
  margin-top: 52px;

  @media (max-width: 600px) {
    margin-top: 22px;
  }
`;

export const Block = styled.div`
  margin-top: 40px;
  width: calc(50% - 25px);

  @media (max-width: 1000px) {
    width: 100%;
    max-width: 600px;
  }

  @media (max-width: 600px) {
    margin-top: 40px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 50px;
  flex-wrap: wrap;

  @media (max-width: 1000px) {
    gap: 0px;
  }
`;

export const BlockName = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 25px;

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 17px;
    margin-bottom: 15px;
  }
`;

export const DeliveryTopWrapper = styled.div`
  display: flex;
`;

export const City = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  padding: 6px 8px 5px 9px;
  background: #f9c646;
  border-radius: 10px;
  display: inline-block;
  margin-left: 11px;
  margin-top: -3px;
`;

export const DeliveryContent = styled.div`
  background: #ffffff;
  box-shadow: 0px 8px 14px rgba(123, 122, 122, 0.1);
  border-radius: 0px 0px 15px 15px;
  padding: 17px;
  position: relative;
`;

export const DeliveryType = styled.div`
  width: 50%;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  padding: 17px 0px 18px 17px;
  cursor: pointer;
  color: #808080;
  display: flex;
  align-items: center;

  ${({ isActive }) =>
    isActive &&
    css`
      background: #ffffff;
      box-shadow: 0px 4px 14px rgba(123, 122, 122, 0.1);
      border-radius: 15px 15px 0px 0px;
    `}

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 15px;
    padding: 13px 0px 13px 17px;
  }
`;

export const Flex = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
`;

export const Total = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  max-width: 520px;
  margin: auto;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

export const TotalWrapper = styled.div`
  margin-top: 100px;

  @media (max-width: 600px) {
    margin-top: 60px;
  }
`;

export const Next = styled.button`
  background-color: #f9c646;
  border-radius: 15px;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  width: 150px;
  height: 42px;
  position: relative;
  cursor: pointer;
  margin: 50px auto;
  display: block;

  @media (max-width: 600px) {
    width: 130px;
    height: 38px;
    margin: 30px auto;
  }
`;

export const EmptyBasket = styled.div`
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.5);
`;
