import React, { useState } from "react";
import { ProductCard } from "../ProductCard";
import * as Styled from "./styled";

export const CategoryBlock = ({ category, products }) => {
  const isSushiCategory = category.name.toLowerCase() === "суши";
  const isPizzaCategory = category.name.toLowerCase() === "пиццы";

  const [isShowAll, setIsShowAll] = useState(false);
  const itemsToShowInRow = document.documentElement.clientWidth <= 760 ? 2 : 3;

  // Чтобы не показывать карточку товара два раза
  const processedProducts = [];

  return (
    <Styled.CategoryBlcok id={category.id}>
      <Styled.CategoryName>{category.name}</Styled.CategoryName>
      <Styled.Products>
        {products
          .slice(0, isShowAll ? products.length : itemsToShowInRow)
          .map((product) => {
            if (product.modification) {
              if (!processedProducts.includes(product.name)) {
                const productWithModification = products.filter(
                  ({ name }) => name === product.name
                );

                processedProducts.push(product.name);

                return (
                  <ProductCard
                    isSushiCategory={isSushiCategory}
                    productWithModification={productWithModification}
                    isPizzaCategory={isPizzaCategory}
                  />
                );
              }

              return null;
            }
            return (
              <ProductCard
                product={product}
                isSushiCategory={isSushiCategory}
                isPizzaCategory={isPizzaCategory}
              />
            );
          })}
      </Styled.Products>
      {!isShowAll && products.length > itemsToShowInRow && (
        <Styled.ShowAll onClick={() => setIsShowAll(true)}>
          Показать все
        </Styled.ShowAll>
      )}
    </Styled.CategoryBlcok>
  );
};
