import styled from "styled-components";

export const Map = styled.iframe`
  margin-top: 30px;
  border: none;
  width: 100%;
  height: 400px;
  margin-bottom: 70px;
`;

export const Block = styled.div`
  padding-left: 80px;
  padding-top: 15px;
  background: url(${({ icon }) => icon});
  background-repeat: no-repeat;
  width: calc(${({ width }) => width} - 20px);
  box-sizing: border-box;

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 50px;
  }

  @media (max-width: 600px) {
    margin-bottom: 30px;
    background-size: 40px;
    padding-left: 61px;
    padding-top: 10px;
  }
`;

export const Wrapper = styled.div`
  margin: 70px 0px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    margin: 20px 0px;
  }
`;

export const Name = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 11px;

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 17px;
  }
`;

export const Content = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #808080;

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export const Phone = styled.a`
  display: block;
  color: inherit;
  text-decoration: none;
  margin-bottom: 10px;
`;
