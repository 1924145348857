import invariant from 'invariant';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import has from 'lodash/has';
import toPairs from 'lodash/toPairs';
import checkStore from './checkStore';

const injectReducersFactory = (store) =>
  function injectReducers(reducers) {
    invariant(
      !isEmpty(reducers),
      '[Store] injectReducers: Expected `reducers` to be an non empty object',
    );

    toPairs(reducers).forEach(([key, reducer]) => {
      invariant(
        isString(key) && !isEmpty(key) && isFunction(reducer),
        `[Store] injectReducers: Expected 'reducer' ${key} to be a reducer function`,
      );
    });

    toPairs(reducers).forEach(([key, reducer]) => {
      // Check `store.injectedReducers[key] === reducer` for hot reloading when a key is the same but a reducer is different
      if (
        has(store.injectedReducers, key) &&
        store.injectedReducers[key] === reducer
      ) {
        return;
      }

      store.injectedReducers[key] = reducer; // eslint-disable-line no-param-reassign
    });

    store.replaceReducer(store.createReducer(store.injectedReducers));
  };

const getReducerInjectors = (store) => {
  checkStore(store);

  return {
    injectReducers: injectReducersFactory(store),
  };
};

export default getReducerInjectors;
