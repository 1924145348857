import React, { useState } from "react";
import { connectToStore } from "store";
import { createStructuredSelector } from "reselect";
import {
  userNameSelector,
  userPhoneSelector,
  userReviewSelector,
  cityInfoSelector,
} from "containers/Application/selectors";
import {
  setUserProperty as setUserPropertyAction,
  closeReviewQuiz as closeReviewQuizAction,
} from "containers/Application/actions";
import { APIService } from "services/APIService";
import * as Styled from "./styled";
import { STEP, STEP_PROGRESS } from "./constants";
import { DirectorMailStep } from "./steps/DirectorMailStep";
import { CommonStep } from "./steps/CommonStep";
import { ChoosePlaceStep } from "./steps/ChoosePlaceStep";
import { ThanksMailStep } from "./steps/ThanksMailStep";
import { ThanksReviewStep } from "./steps/ThanksReviewStep";
import { ReviewFormStep } from "./steps/ReviewFormStep";

const CONFIG = {
  [STEP.COMMON]: CommonStep,
  [STEP.CHOOSE_PLACE]: ChoosePlaceStep,
  [STEP.REVIEW_FORM]: ReviewFormStep,
  [STEP.THANKS_REVIEW]: ThanksReviewStep,
  [STEP.DIRECTOR_MAIL]: DirectorMailStep,
  [STEP.THANKS_MAIL]: ThanksMailStep,
};

const QuizContainer = ({
  cityInfo,
  userName,
  userPhone,
  userReview,
  setUserProperty,
  closeReviewQuiz,
}) => {
  const [stepsHistory, setStepsHistory] = useState([STEP.COMMON]);
  const [countOfStars, setCountOfStars] = useState(5);

  const currentStep = stepsHistory[stepsHistory.length - 1];
  const previousStep = stepsHistory[stepsHistory.length - 2];

  const Step = CONFIG[currentStep];

  const pushNextStep = (step) => {
    setStepsHistory([...stepsHistory, step]);
  };

  const returnBack = () => {
    const next = [...stepsHistory];
    next.pop();

    setStepsHistory(next);
  };

  const submitReview = ({ isMailToDirector = false } = {}) => {
    const data = {
      name: userName.value,
      phone: userPhone.value,
      review: userReview.value,
      stars: countOfStars,
      cityId: cityInfo.id,
      isMailToDirector,
    };

    APIService.sentReview({ data });
  };

  return (
    <Styled.Wrapper>
      <Styled.Form>
        <Styled.TopPanel>
          <Styled.Back onClick={returnBack}>
            {previousStep && "Назад"}
          </Styled.Back>
          <Styled.Close onClick={closeReviewQuiz} />
        </Styled.TopPanel>
        <Styled.ProgressBar>
          <Styled.ProgressBarValue value={STEP_PROGRESS[currentStep]} />
        </Styled.ProgressBar>
        <Styled.StepNumber>
          {STEP_PROGRESS[currentStep] * 100}%
        </Styled.StepNumber>
        <Styled.StepContent>
          <Step
            pushNextStep={pushNextStep}
            cityInfo={cityInfo}
            userName={userName}
            userPhone={userPhone}
            userReview={userReview}
            setUserProperty={setUserProperty}
            submitReview={submitReview}
            setCountOfStars={setCountOfStars}
            countOfStars={countOfStars}
          />
        </Styled.StepContent>
      </Styled.Form>
    </Styled.Wrapper>
  );
};

export const Quiz = connectToStore(
  createStructuredSelector({
    userName: userNameSelector,
    userPhone: userPhoneSelector,
    userReview: userReviewSelector,
    cityInfo: cityInfoSelector,
  }),
  {
    setUserProperty: setUserPropertyAction,
    closeReviewQuiz: closeReviewQuizAction,
  }
)(QuizContainer);
