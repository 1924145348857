import styled from "styled-components";

export const RadioWrapper = styled.div``;

export const RadioCircle = styled.div`
  position: relative;
  width: 15px;
  height: 15px;
  border: 1px solid #b2b2b2;
  box-sizing: border-box;
  border-radius: 50%;
  float: left;
  transition: all 0.2s ease;
`;

export const RadioInputWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: max-content;
  display: flex;
  align-items: center;
  margin: 8px 0px;

  &:hover + ${RadioCircle} {
    border-color: #353535;
  }
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
  cursor: pointer;

  &:checked + ${RadioCircle} {
    border: 5px solid rgb(248, 198, 83);
  }
`;

export const RadioLabel = styled.label`
  padding-left: 15px;
  position: relative;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 15px;
  }
`;
