import React, { useEffect } from "react";
import { connectToStore } from "store";
import { createStructuredSelector } from "reselect";
import { URLS } from "appConstants";
import {
  categoriesSelector,
  productsSelector,
  totalPriceInBasketSelector,
  cityInfoSelector,
} from "containers/Application/selectors";
import { pushNextUrl as pushNextUrlAction } from "containers/Application/actions";
import { Banner } from "components/Banner";
import { Layout } from "components/Layout";
import { CategoryBlock } from "./components/CategoryBlock";
import { Categories } from "./components/Categories";
import basketIcon from "./images/basket.svg";
import * as Styled from "./styled";

const MainPageContainer = ({
  pushNextUrl,
  categories,
  products,
  totalPriceInBasket,
  cityInfo,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getProductsByCategory = (category) => {
    return products.filter((product) => {
      if (category.subCataegories) {
        return (
          category.subCataegories.findIndex(
            ({ id }) => id === product.parentGroup
          ) !== -1
        );
      }

      return product.parentGroup === category.id;
    });
  };

  return (
    <>
      <Banner cityInfo={cityInfo} />
      <Categories categories={categories} />
      <Layout>
        <Styled.Wrapper>
          <Styled.ProductsWrapper>
            {categories.map((category) => (
              <CategoryBlock
                key={category.id}
                category={category}
                products={getProductsByCategory(category)}
              />
            ))}
          </Styled.ProductsWrapper>
          <Styled.Basket
            onClick={() => pushNextUrl(`/${cityInfo.id}${URLS.BASKET}`)}
          >
            <Styled.Icon>
              <img src={basketIcon} alt="корзина" />
            </Styled.Icon>
            <Styled.Line />
            <Styled.Total>{totalPriceInBasket}</Styled.Total>
          </Styled.Basket>
        </Styled.Wrapper>
      </Layout>
    </>
  );
};

export const MainPage = connectToStore(
  createStructuredSelector({
    categories: categoriesSelector,
    products: productsSelector,
    totalPriceInBasket: totalPriceInBasketSelector,
    cityInfo: cityInfoSelector,
  }),
  {
    pushNextUrl: pushNextUrlAction,
  }
)(MainPageContainer);
