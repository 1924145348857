import { LOCAL_STORAGE_KEY, CITIES } from "../constants";

export const getCityfromLocalStorage = () => {
  try {
    const city = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (CITIES.find(({ id }) => city === id)) {
      return city;
    }

    return "";
  } catch (error) {
    return "";
  }
};
