import React from "react";
import ReactDOM from "react-dom";
import { fromJS } from "immutable";
import { Application } from "containers/Application";
// import { NotWork } from "components/NotWork";
import { createStore, StoreProvider } from "store";
import { GlobalStyle } from "./global-styles";

const MOUNT_NODE = document.getElementById("root");

const renderApp = () => {
  const store = createStore({}, fromJS({}));

  ReactDOM.render(
    <StoreProvider store={store}>
      <>
        <GlobalStyle />
        <Application />
        {/* <NotWork /> */}
      </>
    </StoreProvider>,
    MOUNT_NODE
  );
};

renderApp();
