import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 70px 0px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    margin: 20px 0px;
  }
`;

export const InnerWrapper = styled.div`
  margin-top: 20px;
`;

export const Point = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #808080;
  display: flex;
  align-items: center;
  margin-bottom: 11px;
  width: 100%;

  &:before {
    content: "";
    display: block;
    border-radius: 50%;
    min-width: 14px;
    min-height: 14px;
    border: 4px solid rgb(248, 198, 83);
    box-sizing: border-box;
    margin-right: 10px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 17px;
  }
`;
