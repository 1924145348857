const MAX_LENGTH = 64;

const paramsToObject = (entries) => {
  const result = [];

  for (const [key, value] of entries) {
    result.push({
      key: key.substring(0, MAX_LENGTH),
      value: value.substring(0, MAX_LENGTH),
    });
  }

  return result;
};

export const getQueryParams = () => {
  try {
    const urlSearchParams = new URLSearchParams(window.location.search);

    return paramsToObject(urlSearchParams.entries());
  } catch (e) {
    console.error(e);

    return [];
  }
};
