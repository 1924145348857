import React, { useEffect } from "react";
import { Layout } from "components/Layout";
import { TitleL1 } from "components/UI-Kit/Titles";
import * as Styled from "./styled";

export const BirthdayPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Styled.Wrapper>
        <TitleL1>
          Скидка 15% на всю продукцию в день рождения и 7 дней после.
        </TitleL1>
        <Styled.InnerWrapper>
          <Styled.Point>Скидка распространяется на все меню.</Styled.Point>
          <Styled.Point>
            Акция не пересекается и не суммируется с другими скидками и
            спецпредложениями.
          </Styled.Point>
          <Styled.Point>
            Предложением можно воспользоваться один раз в День рождения, один
            день до и семь дней после него.
          </Styled.Point>
          <Styled.Point>
            Скидка распространяется на доставку. Стоимость доставки 5 рублей.
          </Styled.Point>
          <Styled.Point>
            Предъявите документ, удостоверяющий дату рождения администратору в
            кафе или курьеру при доставке.
          </Styled.Point>
        </Styled.InnerWrapper>
      </Styled.Wrapper>
    </Layout>
  );
};
