import { combineReducers as immutableCombineReducers } from 'redux-immutable';

/**
 * Create reducer creator
 *
 * @name createReducerCreator
 * @function
 * @param {Function} [combineReducers = immutableCombineReducers] - create combination of reducers
 * @param {Object} predefinedReducers - original (predefined) reducers
 *
 * @returns {createReducer}
 */
export default (
  combineReducers = immutableCombineReducers,
  predefinedReducers = {},
) =>
  /**
   * Create root reducer
   *
   * @name createReducer
   * @function
   * @param {Object} injectedReducers - injected reducers
   * @returns {Function} - combination of reducers
   */
  (injectedReducers = {}) =>
    combineReducers({
      ...predefinedReducers,
      ...injectedReducers,
    });
