import React, { useEffect } from "react";
import { connectToStore } from "store";
import { createStructuredSelector } from "reselect";
import {
  productsInBasketSelector,
  totalPriceInBasketSelector,
  cityInfoSelector,
} from "containers/Application/selectors";
import {
  addToBasket as addToBasketAction,
  removeFromBasket as removeFromBasketAction,
  totalRemoveFromBasket as totalRemoveFromBasketAction,
  pushNextUrl as pushNextUrlAction,
} from "containers/Application/actions";
import { Layout } from "components/Layout";
import { TitleL1 } from "components/UI-Kit/Titles";
import { URLS } from "appConstants";
import deleteIcon from "./images/delete.svg";
import * as Styled from "./styled";

const BasketPageContainer = ({
  productsInBasket,
  totalPriceInBasket,
  addToBasket,
  removeFromBasket,
  totalRemoveFromBasket,
  pushNextUrl,
  cityInfo,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addToBasketHandler = (product) => {
    addToBasket({ productId: product.id });
  };

  const removeFromBasketHandler = (product) => {
    removeFromBasket({ productId: product.id });
  };

  const deleteFromBasket = (product) => {
    totalRemoveFromBasket({ productId: product.id });
  };

  const getProductTotalPrice = (product) =>
    (product.sizePrices[0].price.currentPrice * product.basket).toFixed(2);

  return (
    <Styled.Basket>
      <Layout>
        <TitleL1>Корзина</TitleL1>
        {productsInBasket.length !== 0 ? (
          <Styled.Wrapper>
            {productsInBasket.map((product) => (
              <Styled.Product>
                <Styled.ProductMain>
                  <Styled.Image src={product.imageLinks[0]} />
                  <div>
                    <Styled.Name>
                      {product.name}{" "}
                      {product.modification && `(${product.modification})`}
                      {product.doughtType && ` (${product.doughtType})`}
                    </Styled.Name>
                    <Styled.Description>
                      {product.description}
                    </Styled.Description>
                  </div>
                </Styled.ProductMain>
                <Styled.Controls>
                  <Styled.ButtonWithActions
                    removeFromBasketHandler={() =>
                      removeFromBasketHandler(product)
                    }
                    addToBasketHandler={() => addToBasketHandler(product)}
                    countInBasket={product.basket}
                  />
                  <Styled.ProductTotalPrice>
                    {getProductTotalPrice(product)}
                    <Styled.Сurrency>руб</Styled.Сurrency>
                  </Styled.ProductTotalPrice>
                  <Styled.Delete
                    src={deleteIcon}
                    onClick={() => deleteFromBasket(product)}
                  />
                </Styled.Controls>
              </Styled.Product>
            ))}
            <Styled.BottomControls>
              <Styled.Return
                onClick={() => pushNextUrl(`/${cityInfo.id}${URLS.MAIN}`)}
              >
                В меню
              </Styled.Return>
              <div>
                <Styled.Total>
                  Сумма заказа:
                  <Styled.ProductTotalPrice>
                    {totalPriceInBasket}
                    <Styled.Сurrency>руб</Styled.Сurrency>
                  </Styled.ProductTotalPrice>
                </Styled.Total>
                <Styled.Next
                  onClick={() => pushNextUrl(`/${cityInfo.id}${URLS.CHECKOUT}`)}
                >
                  Оформить
                </Styled.Next>
              </div>
            </Styled.BottomControls>
          </Styled.Wrapper>
        ) : (
          <Styled.EmptyBasket>Ваша корзина пуста</Styled.EmptyBasket>
        )}
      </Layout>
    </Styled.Basket>
  );
};

export const BasketPage = connectToStore(
  createStructuredSelector({
    productsInBasket: productsInBasketSelector,
    totalPriceInBasket: totalPriceInBasketSelector,
    cityInfo: cityInfoSelector,
  }),
  {
    addToBasket: addToBasketAction,
    removeFromBasket: removeFromBasketAction,
    totalRemoveFromBasket: totalRemoveFromBasketAction,
    pushNextUrl: pushNextUrlAction,
  }
)(BasketPageContainer);
