import React from "react";
import * as Styled from "./styled";

export const TextField = ({
  value,
  setValue,
  placeholder,
  error,
  isTextArea = false,
  isPhoneField = false,
  ...props
}) => {
  const onChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Styled.TextField>
      {isPhoneField && (
        <Styled.PhoneInput
          value={value}
          onChange={onChange}
          {...props}
          error={error}
          mask="+375 99 999 99 99"
          placeholder=" "
        />
      )}
      {isTextArea && (
        <Styled.TextArea
          value={value}
          onChange={onChange}
          {...props}
          error={error}
        />
      )}
      {!isTextArea && !isPhoneField && (
        <Styled.Input
          value={value}
          onChange={onChange}
          {...props}
          error={error}
        />
      )}
      {value ? (
        <Styled.ActiveLabel>{placeholder}</Styled.ActiveLabel>
      ) : (
        <Styled.Label>{placeholder}</Styled.Label>
      )}
      {error && <Styled.Error>{error}</Styled.Error>}
    </Styled.TextField>
  );
};
