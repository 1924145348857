import React, { useEffect, useState } from "react";
import { connectToStore } from "store";
import { createStructuredSelector } from "reselect";
import { Layout } from "components/Layout";
import { TitleL1 } from "components/UI-Kit/Titles";
import {
  cityInfoSelector,
  isReviewQuizOpenSelector,
} from "containers/Application/selectors";
import {
  setUserProperty as setUserPropertyAction,
  openReviewQuiz as openReviewQuizAction,
} from "containers/Application/actions";
import { LIDA_REVIEWS } from "./lidaReviews";
import { DZYARZHYNSK_REVIEWS } from "./dzyarzhynskReviews";
import * as Styled from "./styled";
import { CITY } from "containers/Application/constants";
import { Quiz } from "./Quiz";

export const ReviewsPageContainer = ({
  cityInfo,
  isReviewQuizOpen,
  openReviewQuiz,
}) => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (cityInfo.id === CITY.DZYARZHYNSK) {
      setReviews(DZYARZHYNSK_REVIEWS);
    } else {
      setReviews(LIDA_REVIEWS);
    }
  }, [cityInfo.id]);

  return (
    <>
      {isReviewQuizOpen && <Quiz />}
      <Layout>
        <Styled.Wrapper>
          <Styled.TopPanel>
            <TitleL1>Отзывы ({reviews.length})</TitleL1>
            <Styled.ButtonAddNew onClick={openReviewQuiz}>
              Оставить отзыв
            </Styled.ButtonAddNew>
          </Styled.TopPanel>
          <Styled.Reviews>
            {reviews.map(({ date, name, content, stars }) => (
              <Styled.ReviewWrapper key={date + name}>
                <Styled.Review>
                  <div>
                    <Styled.Name>{name}</Styled.Name>
                    <Styled.Stars count={stars} />
                    <Styled.Content>{content}</Styled.Content>
                  </div>
                  <Styled.Date>{date}</Styled.Date>
                </Styled.Review>
              </Styled.ReviewWrapper>
            ))}
          </Styled.Reviews>
        </Styled.Wrapper>
      </Layout>
    </>
  );
};

export const ReviewsPage = connectToStore(
  createStructuredSelector({
    cityInfo: cityInfoSelector,
    isReviewQuizOpen: isReviewQuizOpenSelector,
  }),
  {
    setUserProperty: setUserPropertyAction,
    openReviewQuiz: openReviewQuizAction,
  }
)(ReviewsPageContainer);
