import styled from "styled-components";

export const ButtonWithActions = styled.div`
  width: 123px;
  height: 42px;
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const ButtonMinus = styled.div`
  background: #f9c646;
  width: 35px;
  border-radius: 15px 0px 0px 15px;
  cursor: pointer;
  font-weight: 500;
`;

export const ButtonPlus = styled(ButtonMinus)`
  border-radius: 0px 15px 15px 0px;
`;

export const CountInBasket = styled.div`
  border-top: 1px solid #f1eeee;
  border-bottom: 1px solid #f1eeee;
  width: calc(100% - 70px);
`;
