import { CITIES } from "../constants";

export const getCityFromURL = () => {
  try {
    const { pathname } = window.location;

    const { id: city = "" } = CITIES.find(({ id }) =>
      pathname.includes(`/${id}`)
    );

    return city;
  } catch (error) {
    return "";
  }
};
