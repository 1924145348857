import React, { useEffect, useState, useRef, useCallback } from "react";
import { Layout } from "components/Layout";
import * as Styled from "./styled";

export const Categories = ({ categories, heightOfBanner }) => {
  const [isStickly, setIsStickly] = useState(null);
  const [minOffsetTop, setMinOffsetTop] = useState(1000);
  const [activeCategoryId, setActiveCategoryId] = useState(1);

  const wrapperEl = useRef(null);

  const isElementView = (el) => {
    var top = el.offsetTop;
    var left = el.offsetLeft;

    while (el.offsetParent) {
      el = el.offsetParent;
      top += el.offsetTop;
      left += el.offsetLeft;
    }

    return (
      top < window.pageYOffset + window.innerHeight - 500
      // top + height > window.pageYOffset
    );
  };

  const handleScroll = useCallback(() => {
    categories.forEach((category) => {
      const element = document.getElementById(category.id);

      if (element && isElementView(element)) {
        setActiveCategoryId(category.id);

        // document.getElementById('categoriesScroll').scrollLeft =
        //   configCategoriesSrcoll[category.id];
      }
    });

    if (wrapperEl && wrapperEl.current) {
      if (wrapperEl.current.offsetTop === minOffsetTop) {
        setIsStickly(false);
      }

      if (wrapperEl.current.offsetTop < minOffsetTop) {
        setMinOffsetTop(wrapperEl.current.offsetTop);
      }

      if (wrapperEl.current.offsetTop > minOffsetTop) {
        setIsStickly(true);
      }
    }
  }, [categories, minOffsetTop]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [
    heightOfBanner,
    setActiveCategoryId,
    activeCategoryId,
    categories,
    handleScroll,
  ]);

  return (
    <Styled.Wrapper isStickly={isStickly} ref={wrapperEl}>
      <Layout>
        <Styled.Categories id="categoriesScroll">
          {categories.map((category) => (
            <Styled.Category
              isActive={activeCategoryId === category.id}
              to={category.id}
              spy={true}
              smooth={true}
              offset={-150}
              key={category.id}
            >
              {category.name}
            </Styled.Category>
          ))}
        </Styled.Categories>
      </Layout>
    </Styled.Wrapper>
  );
};
