import React from "react";
import * as Styled from "../styled";
import step1Image from "../images/step1.png";
import { STEP } from "../constants";

export const CommonStep = ({ pushNextStep }) => (
  <>
    <img src={step1Image} width={70} alt="отзыв" />
    <Styled.StepName>
      Вам понравилось обслуживание в нашем кафе?
    </Styled.StepName>
    <Styled.ButtonsWrapper>
      <Styled.Button onClick={() => pushNextStep(STEP.CHOOSE_PLACE)}>
        Все понравилось
      </Styled.Button>
      <Styled.Button onClick={() => pushNextStep(STEP.DIRECTOR_MAIL)} reverse>
        Есть замечания
      </Styled.Button>
    </Styled.ButtonsWrapper>
  </>
);
