import styled, { css } from "styled-components";
import arrowImage from "./images/arrow.png";

export const Banner = styled.div`
  margin-top: 50px;
  max-width: 1200px;
  margin: auto;
  padding: 0px 10px;
  box-sizing: border-box;
  margin-top: 30px;

  .rec-slider-container {
    box-shadow: 0px 4px 16px rgb(0 0 0 / 10%);
    border-radius: 30px;
    margin: 0 20px;

    @media (max-width: 900px) {
      margin: 0px;
    }
  }
`;

export const ArrowWrapper = styled.div`
  align-items: center;
  display: flex;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const NextArrow = styled.div`
  background-image: url(${arrowImage});
  width: 31px;
  height: 31px;
  cursor: ${({ isActive }) => (isActive ? "pointer" : "not-allowed")};
  opacity: ${({ isActive }) => (isActive ? "1" : "0.5")};

  ${({ reverse }) =>
    reverse &&
    css`
      transform: rotate(180deg);
    `}
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${({ isActive }) => (isActive ? "#000000" : "#C4C4C4")};
  cursor: pointer;
  margin: 5px;
`;

export const Dots = styled.div`
  display: flex;
  margin-top: 27px;
`;

export const Slide = styled.img`
  width: 100%;
  cursor: pointer;
`;
