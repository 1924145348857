import React from "react";
import { connectToStore } from "store";
import { createStructuredSelector } from "reselect";
import { cityInfoSelector } from "containers/Application/selectors";
import { pushNextUrl as pushNextUrlAction } from "containers/Application/actions";
import { Layout } from "components/Layout";
import { URLS } from "appConstants";
import vkIcon from "./images/vk.svg";
import instagramIcon from "./images/instagram.svg";
import * as Styled from "./styled";

export const FooterContainer = ({ pushNextUrl, cityInfo }) => {
  const openReviews = (e) => {
    e.preventDefault();
    pushNextUrl(`/${cityInfo.id}${URLS.REVIEWS}`);
  };

  const openDelivery = (e) => {
    e.preventDefault();
    pushNextUrl(`/${cityInfo.id}${URLS.DELIVERY}`);
  };

  const openMain = (e) => {
    e.preventDefault();
    pushNextUrl(`/${cityInfo.id}${URLS.MAIN}`);
  };

  return (
    <Styled.Footer>
      <Layout>
        <Styled.Wrapper>
          <Styled.Logo />
          <div>
            <Styled.Title>Покупателям</Styled.Title>
            <Styled.Link href={URLS.MAIN} onClick={openMain}>
              Меню
            </Styled.Link>
            <Styled.Link href={URLS.DELIVERY} onClick={openDelivery}>
              Доставка и оплата
            </Styled.Link>
            <Styled.Link href={URLS.REVIEWS} onClick={openReviews}>
              Отзывы
            </Styled.Link>
          </div>
          <div>
            <Styled.Title>Контакты</Styled.Title>
            <Styled.Link href={`tel:${cityInfo.phone}`}>
              {cityInfo.phone_label}
            </Styled.Link>
            <Styled.SocialWrapper>
              {cityInfo.vk_link && (
                <Styled.Social href={cityInfo.vk_link} icon={vkIcon} />
              )}
              <Styled.Social
                href={cityInfo.instagram_link}
                icon={instagramIcon}
              />
            </Styled.SocialWrapper>
          </div>
          <Styled.Info>
            ООО "Фуд ивент" УНП 691636983 <br /> г.Дзержинск, ул.Пераможцев 1Б
            пом.2
          </Styled.Info>
        </Styled.Wrapper>
      </Layout>
    </Styled.Footer>
  );
};

export const Footer = connectToStore(
  createStructuredSelector({
    cityInfo: cityInfoSelector,
  }),
  {
    pushNextUrl: pushNextUrlAction,
  }
)(FooterContainer);
