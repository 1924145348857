import { URLS } from "appConstants";
import birthdayBanner from "./images/birthday.png";

export const BANNERS = [
  {
    key: "birthday",
    image: birthdayBanner,
    url: URLS.BIRTHDAY,
  },
];
