import styled, { keyframes } from "styled-components";

export const Progress = styled.div`
  position: relative;
  width: 100%;
  height: 3px;
  overflow: hidden;
  z-index: 100;
`;

const progressBar = keyframes`
  0% {
    transform: translate(-50%) scaleX(0);
  }

  50% {
    transform: translate(-0%) scaleX(0.3);
  }

  100% {
    transform: translate(50%) scaleX(0);
  }
`;

export const ProgressValue = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f9c646;;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.2s;
  transform: scaleX(0);
  transform-origin: center center;
  animation: ${progressBar} 1s linear infinite;
`;
