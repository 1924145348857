import styled from "styled-components";
import star from "./images/star.png";

export const Wrapper = styled.div`
  margin-top: 47px;

  @media (max-width: 600px) {
    margin-top: 20px;
  }
`;

export const Reviews = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 37px -7px 100px;

  @media (max-width: 600px) {
    margin: 20px -7px 40px;
  }
`;

export const ReviewWrapper = styled.div`
  width: 33.33%;
  box-sizing: border-box;
  padding: 7px;

  @media (max-width: 1100px) {
    width: 50%;
  }

  @media (max-width: 560px) {
    width: 100%;
  }
`;

export const Review = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(123, 122, 122, 0.1);
  border-radius: 30px;
  padding: 22px 30px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 600px) {
    padding: 20px;
  }
`;

export const Name = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 10px;

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 17px;
  }
`;

export const Content = styled.div`
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 30px;
  white-space: pre-line;

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export const Stars = styled.div`
  width: ${(props) => props.count * 14}px;
  height: 14px;
  background: url(${star});
  background-size: contain;
`;

export const Date = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #808080;

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export const ButtonAddNew = styled.button`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  background: #f9c646;
  padding: 13px 17px;
  border-radius: 15px;
  border: none;
  outline: none;
  margin-left: 36px;
  cursor: pointer;
  transition: all 0.2s ease 0s;

  &:active {
    transform: translateY(1px);
  }

  &:hover {
    background-color: #ebb42c;
    box-shadow: 0px 4px 14px rgba(123, 122, 122, 0.1);
  }

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 17px;
    padding: 10px 15px;
    margin-left: 20px;
  }
`;

export const TopPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 600px) {
    justify-content: space-between;
  }
`;
