import React from 'react';
import { combineReducers as immutableCombineReducers } from 'redux-immutable';

/**
 * @typedef {Object} StoreEnvOptions - options for store environment
 * @property {Object} [context = React.createContext(null)] - context to witch store would be connected.
 * @property {Function} [combineReducers = immutableCombineReducers] - function for creating combination of reducers
 */

/**
 * @name preparaStoreEnvOptions
 * @function
 * @param {StoreEnvOptions} storeEnvOptions - options for store environment
 */
export default (storeEnvOptions = {}) => {
  const {
    combineReducers = immutableCombineReducers,
    context,
  } = storeEnvOptions;
  const storeContext = context || React.createContext(null);

  return {
    context: storeContext,
    combineReducers,
  };
};
