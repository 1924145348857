import styled from "styled-components";
import logoImage from "./images/logo.png";
import phoneImage from "./images/phone.png";
import hamburgerImage from "./images/hamburger.svg";
import closeImage from "./images/close.svg";
import arrowIcon from "./images/arrow.svg";
import pointIcon from "./images/point.svg";

export const Header = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(158, 154, 154, 0.05);
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 99999;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.a`
  height: 87px;
  width: 77px;
  display: block;
  background-image: url(${logoImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 960px) {
    height: 65px;
    width: 60px;
  }
`;

export const Link = styled.a`
  font-weight: normal;
  font-size: 19px;
  line-height: 24px;
  color: #000000;
  text-decoration: none;
  margin: 0px 10px;
  border-bottom: 2px solid
    ${({ isActive }) => (isActive ? "#f2c75e" : "transparent")};
  transition: all 0.25s;

  &:hover {
    border-color: #f2c75e;
  }

  @media (max-width: 960px) {
    border-color: transparent !important;
    margin-bottom: 5px;
  }
`;

export const Menu = styled(Flex)`
  @media (max-width: 960px) {
    background: #fff;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    transition: all 0.3s;
    border-top: 1px solid rgba(45, 45, 45, 0.1);
    transform: ${(props) =>
      props.isOpenMobileMenu ? "translateX(0px)" : "translateX(-100%)"};
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    height: 100vh;
    padding: 70px 0px 0px 10px;
    z-index: -1;
  }
`;

export const Phone = styled.a`
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  text-decoration: none;
  margin: 1px 0px;

  @media (max-width: 1080px) {
    display: none;
  }
`;

export const MobilePhone = styled.a`
  background: url(${phoneImage});
  width: 25px;
  height: 25px;
  background-size: contain;
  opacity: 0.8;
  cursor: pointer;
  display: none;

  @media (max-width: 960px) {
    display: block;
  }
`;

export const Hamburger = styled.button`
  background: url(${(props) =>
    props.isOpenMobileMenu ? closeImage : hamburgerImage});
  background-repeat: no-repeat;
  background-size: contain;
  width: 27px;
  height: ${(props) => (props.isOpenMobileMenu ? "17px" : "14px")};
  border: none;
  outline: none !important;
  padding: 0px;
  opacity: 0.8;
  display: none;
  cursor: pointer;

  @media (max-width: 960px) {
    display: block;
  }
`;

export const ChooseCity = styled.select`
  outline: none;
  border: 1px solid #f1eeee;
  border-radius: 15px;
  padding: 10px 35px 10px 45px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  -webkit-appearance: none;
  cursor: pointer;
  background-image: url(${arrowIcon});
  background-position: calc(100% - 10px);
  background-repeat: no-repeat;
  margin-right: 15px;
  background-color: #fff;
  color: #000;

  &:hover {
    border-color: #f2c75e;
  }
`;

export const ChooseCityWrapper = styled.div`
  position: relative;

  @media (max-width: 1080px) {
    margin-right: 0px;
  }

  @media (max-width: 960px) {
    margin-left: 10px;
    margin-top: 10px;
  }
`;

export const Point = styled.div`
  background: url(${pointIcon});
  width: 18px;
  height: 19px;
  position: absolute;
  top: 11px;
  left: 14px;
`;

export const MobileCity = styled.div`
  display: none;

  @media (max-width: 960px) {
    display: block;
  }
`;

export const DesktopWrapper = styled.div`
  display: block;

  @media (max-width: 960px) {
    display: none;
  }
`;
