import styled from "styled-components";

export const ModalDialog = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(158, 154, 154, 0.05);
  width: 100%;
`;

export const Wrapper = styled.div`
  width: 100vw !important;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  display: block;
`;

export const Dialog = styled.div`
  background: #fff;
  box-shadow: rgb(0 0 0 / 5%) 5px 5px 5px;
  border-radius: 10px;
  width: ${({ large }) => (large ? "800px" : "500px")}!important;
  left: ${({ large }) => (large ? "calc(50% - 400px)" : "calc(50% - 250px)")};
  top: 50px;
  position: fixed;
  z-index: 9999999;

  @media (max-width: ${({ large }) => (large ? "880px" : "600px")}) {
    width: calc(100% - 40px) !important;
    left: 20px;
  }
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  padding: 10px 15px;
  border-bottom: 2px solid rgb(248, 198, 83);
`;

export const Content = styled.div`
  padding: 25px 15px 20px;
`;
