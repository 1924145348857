import React from "react";
import { TextField } from "components/UI-Kit/TextField";
import * as Styled from "../styled";
import { STEP } from "../constants";

export const ReviewFormStep = ({
  pushNextStep,
  userName,
  userPhone,
  userReview,
  setUserProperty,
  submitReview,
  setCountOfStars,
  countOfStars,
}) => (
  <>
    <Styled.StarWrapper>
      <Styled.Star onClick={() => setCountOfStars(1)} />
      <Styled.Star onClick={() => setCountOfStars(2)} />
      <Styled.Star onClick={() => setCountOfStars(3)} />
      <Styled.Star onClick={() => setCountOfStars(4)} />
      <Styled.Star onClick={() => setCountOfStars(5)} />
      <Styled.StarOpacity countOfStars={countOfStars} />
    </Styled.StarWrapper>
    <Styled.FormWrapper>
      <Styled.FieldsWrapper>
        <TextField
          value={userName.value}
          setValue={(value) => setUserProperty({ id: userName.id, value })}
          placeholder="Имя*"
          error={userName.error}
        />
        <TextField
          value={userPhone.value}
          setValue={(value) => setUserProperty({ id: userPhone.id, value })}
          placeholder="Номер телефона*"
          error={userPhone.error}
          isPhoneField
        />
      </Styled.FieldsWrapper>
      <TextField
        value={userReview.value}
        setValue={(value) => setUserProperty({ id: userReview.id, value })}
        placeholder="Отзыв"
        error={userReview.error}
        rows={5}
        isTextArea
      />
      <Styled.Button
        onClick={() => {
          submitReview();
          pushNextStep(STEP.THANKS_REVIEW);
        }}
      >
        Отправить
      </Styled.Button>
    </Styled.FormWrapper>
  </>
);
