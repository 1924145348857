import createMiddleware from 'redux-saga';
import { SagaErrorHandler } from './SagaErrorHandler';

export function createSagaMiddleware() {
  const middleware = createMiddleware({
    onError: SagaErrorHandler.catchError,
  });

  return middleware;
}
