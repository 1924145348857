import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

/**
 * Creates store provider with using unique store key in context
 *
 * @name createApplicationStoreProvider
 * @function
 * @param {StoreEnvOptions} storeEnvOptions
 * @returns {ApplicationStoreProvider}
 */
export default ({ context }) =>
  class ApplicationStoreProvider extends PureComponent {
    static propTypes = {
      store: PropTypes.object.isRequired,
      children: PropTypes.element.isRequired,
    };

    render() {
      return (
        <Provider context={context} store={this.props.store}>
          {this.props.children}
        </Provider>
      );
    }
  };
