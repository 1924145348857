export const LIDA_REVIEWS = [
  {
    content:
      "Отличный и вежливый персонал. Вкуснейшая пицца. Решила написать отзыв после общения с приятнейшей девушкой по телефону",
    date: "28.01.2024",
    name: "Анастасия",
    stars: "5",
  },
  {
    content:
      "Заказала с детьми пиццу маргариту и креветки с ананасом. Доставка быстрая и пиццы вкусные. Спасибо       ",
    date: "25.01.2024",
    name: "Евгения",
    stars: "5",
  },
  {
    content:
      "Очень вкусные пиццы и суши, быстрая подача. Рекомендую👍      ",
    date: "20.01.2024",
    name: "Наталия",
    stars: "5",
  },
  {
    content:
      "Замечательное кафе!!! Самый вкусный горячий сет❤️❤️❤️ Команда также лучшая!",
    date: "27.12.2023",
    name: "Дарья",
    stars: "5",
  },
  {
    content:
      "Заказывал вчера роллы запичённые всё на высшем уровне вкусно не вероятно как , рад что брал именно у вас 👍",
    date: "21.12.2023",
    name: "Роман",
    stars: "5",
  },
  {
    content:
      "Прекрасное заведение!      ",
    date: "25.11.2023",
    name: "Стас",
    stars: "5",
  },
  {
    content:
      "Заказывали две пиццы Ветчина с грибами. Пицца сытная, горячая, ароматная и очень вкусная! Щедрая начинка и быстрое изготовление. Спасибо!      ",
    date: "27.10.2023",
    name: "Галина",
    stars: "5",
  },
  {
    content:
      "Спасибо за пиццу! Доставка быстрая, пицца горячая и обалденно вкусная. Рекомендую!!!!",
    date: "27.10.2023",
    name: "Татьяна",
    stars: "5",
  },
  {
    content:
      "Делали заказ вечером в воскресенье, сделали всё быстро, ролы вкусные, в картофель фри 🍟 просили специи не добавлять (для ребенка) и это учли, обычно везде забывают... Спасибо!      ",
    date: "15.10.2023",
    name: "Наталья",
    stars: "5",
  },
  {
    content:
      "Заказывали не раз. Все очень вкусно!)",
    date: "02.10.2023",
    name: "Юлия",
    stars: "5",
  },
  {
    content:
      "Заказываем регулярно. Все как всегда на высоте!",
    date: "29.09.2023",
    name: "Анна",
    stars: "5",
  },
  {
    content:
      "Брали сет Прайм, все вкусно, доставка вовремя      ",
    date: "24.09.2023",
    name: "Ольга",
    stars: "5",
  },
  {
    content:
      "Не помню, как можно приготовить такую вкусно пиццу за такое короткое время?!",
    date: "17.09.2023",
    name: "Иван",
    stars: "5",
  },
  {
    content:
      "Отличная кухня",
    date: "13.09.2023",
    name: "Сергей",
    stars: "5",
  },
  {
    content:
      "Как всегда всё очень очень вкусно! Огромное спасибо за быструю готовность заказов. 👍👍👍",
    date: "10.09.2023",
    name: "Виктория",
    stars: "5",
  },
  {
    content:
      "Пицы очень вкусные, самые лучшие в Лиде. Всегда берем карбанару и пеперони. ",
    date: "20.08.2023",
    name: "Инна",
    stars: "5",
  },
  {
    content:
      "Все очень вкусно и быстро. Спасибо! ",
    date: "13.08.2023",
    name: "Елена",
    stars: "5",
  },
  {
    content:
      "Спасибо! Мы заказывали пиццу впервые в SVOE кафе. Нам понравилось. Вкусное тесто и много начинки! Внешне выглядела как на фото . Супер! Будем заказывать еще      ",
    date: "08.08.2023",
    name: "Валерия",
    stars: "5",
  },
  {
    content:
      "Спасибо за вкусную еду, получили заказ вовремя, все свежее и вкусное      ",
    date: "24.07.2023",
    name: "Екатерина",
    stars: "5",
  },
  {
    content:
      "Первый раз заказывала в этом кафе пиццы. Очень довольна качеством обслуживания. Пиццы все очень вкусные, все красиво оформлено, начинки много, продукты свежие. Спасибо вам! Было приятно посетить ваше кафе!!!",
    date: "19.07.2023",
    name: "Оксана",
    stars: "5",
  },
  {
    content: "Все очень вкусно и качественно!спасибо!",
    date: "13.07.2023",
    name: "Инна",
    stars: "5",
  },
  {
    content:
      "Лучшие суши по городу Лида. Приятный персонал, который поможет с выбором своей продукции. Удобное расположение.",
    date: "09.07.2023",
    name: "Артём",
    stars: "5",
  },
  {
    content:
      "Спасибо большое за вкусную  🍕пиццу и  суши🍣! Детям очень понравилось все!! Будем с удовольствием заказывать ещё!",
    date: "02.07.2023",
    name: "Светлана",
    stars: "5",
  },
  {
    content:
      "Заказывала вечером на утро. Заказ получили вовремя. Все очень вкусное. Большое спасибо!",
    date: "11.06.2023",
    name: "Светлана",
    stars: "5",
  },
  {
    content: "Все очень вкусно и быстро😉",
    date: "09.06.2023",
    name: "Никита",
    stars: "5",
  },
  {
    content:
      "Уже много раз заказывали и будем заказывать ещё, приветливый персонал, сообщает об акциях, свежие суши. Одни из самых вкусных сушей в Лиде 👍",
    date: "10.05.2023",
    name: "Никита",
    stars: "5",
  },
  {
    content: "Быстрое и вежливое обслуживание, вкусный бокс Гамбургер",
    date: "01.05.2023",
    name: "Инесса",
    stars: "5",
  },
  {
    content: "Отличная пиццерия, на мой взгляд самые вкусные пиццы в городе",
    date: "20.04.2023",
    name: "Сергей",
    stars: "5",
  },
  {
    content:
      "Вкусная пицца, отличное мягкое тесто, быстро приготовлена. Рекомендую.",
    date: "02.04.2023",
    name: "Наталья",
    stars: "5",
  },
  {
    content:
      "Здравствуйте. Обожаем Вашу продукцию, всегда все свежее, горячее, вкусное. Доставка супер быстрая. Спасибо большое за Ваш труд!",
    date: "24.03.2023",
    name: "Екатерина",
    stars: "5",
  },
  {
    content:
      "Спасибо за заказ.\nВсе очень вкусно. Не первый раз заказываю, к качеству кухни никаких претензий нет.",
    date: "21.01.2023",
    name: "Татьяна",
    stars: "4",
  },
  {
    content: "Очень вкусные пиццы,рекомендую.",
    date: "13.01.2023",
    name: "Елена",
    stars: "4",
  },
  {
    content:
      "Самый вкусный кофе и картофель фри в кафе svoe, которые любят даже дети!",
    date: "14.10.2022",
    name: "Ольга",
    stars: "4",
  },
  {
    content:
      "Покупала бургер 🍔 с говядиной 🐄.  Очень понравилось.  Достойный вкус за приличную цену. Наверное лучший бургер со всей Лиды.",
    date: "05.10.2022",
    name: "Виктория",
    stars: "5",
  },
  {
    content: "Всё очень вкусно! Суши и пицца шикарные! Будем заказывать ещё)",
    date: "01.10.2022",
    name: "Алеся",
    stars: "5",
  },
  {
    content:
      "Поке отличные,все было очень вкусно. Роллы бонито хот очень вкусные,таких ещё в нашем городе никогда не пробовал",
    date: "04.07.2022",
    name: "Егор",
    stars: "5",
  },
  {
    content:
      "Очень вкусная еда,все ингредиенты вкусные, вот только в карбонаре пицце не ложите огурцы а так 👍",
    date: "02.09.2022",
    name: "Тимофей",
    stars: "5",
  },
];
