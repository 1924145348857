import { createStore, applyMiddleware, compose } from 'redux';
import { createSagaMiddleware } from './saga/sagaMiddleware';
import createReducerCreator from './createReducerCreator';

const sagaMiddleware = createSagaMiddleware();

/**
 * Create store creator by environment options
 *
 * @name createStoreCreator
 * @function
 * @param {StoreEnvOptions} storeEnvOptions
 */
export default ({ combineReducers }) =>
  /**
   * Create redux store with possibility to inject reducers and sagas
   *
   * @name createStore
   * @function
   * @param {Object} [predefinedReducers = {}] - an object, which describes an application reducers
   * @param {*} [initialState = {}] - the initial state of application
   * @param {Array<Function>} middleware - middleware which are used in application
   * @returns {Store}
   */
  (predefinedReducers = {}, initialState = {}, middleware = []) => {
    const enhancers = [applyMiddleware(...middleware, sagaMiddleware)];

    // If Redux DevTools Extension is installed use it, otherwise use Redux compose
    /* eslint-disable no-underscore-dangle */
    const composeEnhancers =
      process.env.NODE_ENV !== 'production' &&
      typeof window === 'object' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Prevent recomputing reducers for `replaceReducer`
            shouldHotReload: false,
          })
        : compose;
    /* eslint-enable */

    const createReducer = createReducerCreator(
      combineReducers,
      predefinedReducers,
    );
    const store = createStore(
      createReducer(predefinedReducers),
      initialState,
      composeEnhancers(...enhancers),
    );

    // Extensions
    store.runSaga = sagaMiddleware.run;
    store.injectedReducers = {}; // Reducer registry
    store.injectedSagas = {}; // Saga registry
    store.createReducer = createReducer;

    return store;
  };
