import React from "react";
import * as Styled from "./styled";

export const RadioField = ({ options, setOption, value, name }) => {
  const onChange = (event) => {
    setOption(event.target.value);
  };

  return (
    <Styled.RadioWrapper onChange={onChange}>
      {options.map((option) => (
        <Styled.RadioInputWrapper>
          <Styled.Input
            type="radio"
            checked={value == option.value}
            value={option.value}
            name={name}
          />
          <Styled.RadioCircle />
          <Styled.RadioLabel>{option.label}</Styled.RadioLabel>
        </Styled.RadioInputWrapper>
      ))}
    </Styled.RadioWrapper>
  );
};
