import { takeLatest, call, put, take, select } from "redux-saga/effects";
import { APIService } from "services/APIService";
import { appConfig } from "appConfig";
import { ORDER_STATUS } from "appConstants";
import {
  AnalyticsService,
  EVENTS,
  ANALYTICS_OPTIONS,
} from "services/AnalyticsService";
import { history } from "./history";
import {
  startApplication,
  setCityInfo,
  setIsInitialized,
  loadMenu,
  palceAnOrder,
  setLoading,
  validateProperties,
  pushNextUrl,
} from "./actions";
import {
  userPayTypeSelector,
  userDeliveryTypeSelector,
  makeSelectIsValidProperties,
  productsInBasketSelector,
  userSelector,
  totalPriceInBasketSelector,
  deliveryPriceSelector,
  cityInfoSelector,
  totalSumToPaySelector,
} from "./selectors";
import { getCityFromURL } from "./utils/getCityFromURL";
import { getCityfromLocalStorage } from "./utils/getCityfromLocalStorage";
import { getQueryParams } from "./utils/getQueryParams";
import {
  CITIES,
  USER_PROPERTY_ID,
  PAY_TYPES,
  DELIVERY_TYPES,
  LOCAL_STORAGE_KEY,
} from "./constants";

export class ApplicationSagaWorker {
  static *startApplication(action) {
    try {
      yield call(APIService.init, {
        backendUrl: appConfig.API_BASE_URL,
      });

      const { token } = yield call(APIService.getToken);

      APIService.setToken({ token });

      const detectedCity = yield call(ApplicationSagaWorker.tryToDetectCity);

      let cityInfo = CITIES[0];

      if (detectedCity) {
        cityInfo = CITIES.find(({ id }) => id === detectedCity);
      }

      const { origin, pathname, search } = window.location;

      if (!pathname.includes(cityInfo.id)) {
        window.location.replace(origin + "/" + cityInfo.id + pathname + search);
      }

      yield call(AnalyticsService.init, {
        city: cityInfo.id,
        isEnabled: appConfig.isAnalyticsEnabled,
      });

      yield put(setCityInfo({ cityInfo }));

      yield put(loadMenu.request());
      yield take(loadMenu.recieve);

      yield put(setIsInitialized(true));
    } catch (error) {
      console.error("error", error);
    }
  }

  // eslint-disable-next-line require-yield
  static *setCityInfoToStorage(action) {
    const { cityInfo } = action.payload;

    try {
      localStorage.setItem(LOCAL_STORAGE_KEY, cityInfo.id);
    } catch (error) {
      console.error("error", error);
      return "";
    }
  }

  static *tryToDetectCity() {
    try {
      const cityfromURL = yield call(getCityFromURL);
      const cityfromLocalStorage = yield call(getCityfromLocalStorage);

      return cityfromURL || cityfromLocalStorage || "";
    } catch (error) {
      console.error("error", error);
      return "";
    }
  }

  static *loadMenu() {
    try {
      const menu = yield call(APIService.loadMenu);

      yield put(loadMenu.recieve(menu));
    } catch (error) {
      console.error("error", error);
    }
  }

  static *palceAnOrder() {
    try {
      yield put(setLoading(true));
      const propertiesForValidation = [
        USER_PROPERTY_ID.NAME,
        USER_PROPERTY_ID.PHONE,
        USER_PROPERTY_ID.COMMENT,
        USER_PROPERTY_ID.PAY_TYPE,
        USER_PROPERTY_ID.DELIVERY_TYPE,
      ];

      const userPayType = yield select(userPayTypeSelector);
      const userDeliveryType = yield select(userDeliveryTypeSelector);

      if (userPayType.value === PAY_TYPES.CASH) {
        propertiesForValidation.push(USER_PROPERTY_ID.BANKNOTE);
      }

      if (userDeliveryType.value === DELIVERY_TYPES.DELIVERY) {
        propertiesForValidation.push(
          USER_PROPERTY_ID.STREET,
          USER_PROPERTY_ID.HOUSE_NUMBER,
          USER_PROPERTY_ID.FLAT_NUMBER,
          USER_PROPERTY_ID.ENTRANCE,
          USER_PROPERTY_ID.FLOOR
        );
      }

      yield put(validateProperties(propertiesForValidation));

      const isValid = yield select(
        makeSelectIsValidProperties(propertiesForValidation)
      );

      if (isValid) {
        const productsInBasket = yield select(productsInBasketSelector);
        const totalPriceInBasket = yield select(totalPriceInBasketSelector);
        const totalSumToPay = yield select(totalSumToPaySelector);
        const deliveryPrice = yield select(deliveryPriceSelector);
        const cityInfo = yield select(cityInfoSelector);

        const products = productsInBasket.map((product) => ({
          id: product.id,
          name: product.name,
          price: product.sizePrices[0].price.currentPrice,
          modification: product.modification,
          doughtType: product.doughtType,
          amount: product.basket,
        }));

        const user = yield select(userSelector);
        const data = { totalPriceInBasket, totalSumToPay, cityId: cityInfo.id };

        if (userDeliveryType.value === DELIVERY_TYPES.DELIVERY) {
          data.deliveryPrice = deliveryPrice;
        }

        propertiesForValidation.forEach((propertyId) => {
          data[propertyId] = user[propertyId].value;
        });

        const { success } = yield call(APIService.palceAnOrder, {
          data,
          products,
          params: [...getQueryParams()],
        });

        if (success) {
          yield put(pushNextUrl(`/${cityInfo.id}/${ORDER_STATUS.SUCCESS}`));

          yield call(AnalyticsService.track, {
            event: EVENTS.ORDER_SUCCESS,
            options: {
              [ANALYTICS_OPTIONS.ORDER_VALUE]: totalSumToPay,
            },
          });
        } else {
          yield put(pushNextUrl(`/${cityInfo.id}/${ORDER_STATUS.FAILURE}`));

          yield call(AnalyticsService.track, {
            event: EVENTS.ORDER_FAILURE,
            options: {
              [ANALYTICS_OPTIONS.ORDER_VALUE]: totalSumToPay,
            },
          });
        }
      }

      yield put(setLoading(false));
    } catch (error) {
      yield put(setLoading(false));
      console.error("error", error);
    }
  }

  static *pushNextUrl({ payload }) {
    try {
      yield call([history, "push"], {
        pathname: payload,
        search: window.location.search,
      });
    } catch (error) {
      console.error("error", error);
    }
  }
}

export function* applicationSagaWatcher() {
  yield takeLatest(startApplication, ApplicationSagaWorker.startApplication);
  yield takeLatest(loadMenu.request, ApplicationSagaWorker.loadMenu);
  yield takeLatest(palceAnOrder, ApplicationSagaWorker.palceAnOrder);
  yield takeLatest(setCityInfo, ApplicationSagaWorker.setCityInfoToStorage);
  yield takeLatest(pushNextUrl, ApplicationSagaWorker.pushNextUrl);
}
