import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connectToStore } from "store";
import { createStructuredSelector } from "reselect";
import { ORDER_STATUS } from "appConstants";
import { cityInfoSelector } from "containers/Application/selectors";
import successIcon from "./images/success.png";
import * as Styled from "./styled";

export const ThankYouPageContainer = ({ cityInfo }) => {
  const { status } = useParams();

  const isSuccessOrder = status === ORDER_STATUS.SUCCESS;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (isSuccessOrder) {
      // setTimeout(() => {
      //   const { origin, search } = window.location;

      //   window.location.replace(origin + "/" + cityInfo.id + search);
      // }, 4000);
    }
  }, [cityInfo.id, isSuccessOrder]);

  const getTitle = () =>
    isSuccessOrder ? "Заказ успешно оформлен!" : "Что-то пошло не так :(";

  const getContent = () =>
    isSuccessOrder
      ? "Наш менеджер свяжется с вами в ближайшее время, приятного аппетита!"
      : "Пожалуйста, перезагрузите страницу и попробуйте еще раз";

  return (
    <Styled.Layout>
      <div>
        {isSuccessOrder && <Styled.SuccessIcon src={successIcon} />}
        <Styled.SuccessTitle>{getTitle()}</Styled.SuccessTitle>
        <Styled.SuccessContent>{getContent()}</Styled.SuccessContent>
      </div>
    </Styled.Layout>
  );
};

export const ThankYouPage = connectToStore(
  createStructuredSelector({
    cityInfo: cityInfoSelector,
  })
)(ThankYouPageContainer);
