import styled from "styled-components";
import logoImage from "./images/logo.png";

export const Footer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(123, 122, 122, 0.1);
  padding: 60px 40px;

  @media (max-width: 600px) {
    padding: 30px 20px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;

    > div {
      margin-bottom: 20px;
    }
  }
`;

export const Logo = styled.img.attrs({
  src: logoImage,
})`
  height: auto;
  width: 77px;

  @media (max-width: 1000px) {
    margin-bottom: 15px;
    margin-right: -14px;
  }
`;

export const Info = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #808080;

  @media (max-width: 1000px) {
    text-align: center;
  }
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 25px;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const Link = styled.a`
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 10px;
  display: block;
  text-decoration: none;

  @media (max-width: 1000px) {
    text-align: center;
  }

  @media (max-width: 600px) {
    font-size: 16px;
    margin-bottom: 5px;
  }
`;

export const Social = styled.a`
  display: inline-block;
  width: 37px;
  height: 37px;
  background-image: url(${({ icon }) => icon});
  background-repeat: no-repeat;
  margin-right: 11px;
  margin-top: 10px;

  @media (max-width: 1000px) {
    margin: 5px;
  }
`;

export const SocialWrapper = styled.div`
  display: flex;
  justify-content: flex-start;

  @media (max-width: 1000px) {
    justify-content: center;
  }
`;
