import styled from "styled-components";
import arrowIcon from "./inages/arrow.svg";

export const CategoryBlcok = styled.div`
  margin-top: 40px;
`;

export const CategoryName = styled.div`
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  color: #000000;

  @media (max-width: 760px) {
    font-size: 18px;
    line-height: 17px;
  }
`;

export const Products = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px -10px 0px;

  > div {
    width: 33.3%;
  }

  @media (max-width: 760px) {
    > div {
      width: 50%;
    }
  }
`;

export const ShowAll = styled.div`
  line-height: 21px;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(249, 198, 70);
  width: fit-content;
  margin: 10px auto;
  color: #000;
  padding: 10px 15px;
  border-radius: 15px;
  font-weight: 500;
  font-size: 16px;

  &:after {
    content: "";
    display: block;
    background: url(${arrowIcon});
    height: 11px;
    width: 11px;
    margin-left: 6px;
  }
`;
