import { createStoreEnvironment } from './store';
import { createSelectorHook, createDispatchHook } from 'react-redux';
import { DAEMON } from './constants';

const SAGA_MODE = {
  DAEMON,
};

const {
  createStore,
  injectReducer,
  injectSaga,
  injectMultipleReducers,
  StoreProvider,
  connectToStore,
  injectMultipleSagas,
  StoreContext,
} = createStoreEnvironment();

const useSelector = createSelectorHook(StoreContext);
const useDispatch = createDispatchHook(StoreContext);

export {
  createStore,
  injectReducer,
  injectSaga,
  injectMultipleReducers,
  StoreProvider,
  connectToStore,
  SAGA_MODE,
  injectMultipleSagas,
  useSelector,
  useDispatch,
};
