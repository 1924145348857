import { createAction } from "redux-actions";

export const startApplication = createAction("start_application");
export const setIsInitialized = createAction("set_is_initialized");

export const setCityInfo = createAction("set_city_info");

export const loadMenu = {
  recieve: createAction("load_menu_recieve"),
  request: createAction("load_menu_request"),
};

export const addToBasket = createAction("add_to_basket");
export const removeFromBasket = createAction("remove_from_basket");
export const totalRemoveFromBasket = createAction("total_remove_from_basket");

export const setUserProperty = createAction("set_user_property");
export const validateProperties = createAction("validate_properties");

export const palceAnOrder = createAction("palce_an_order");

export const setLoading = createAction("set_loading");

export const pushNextUrl = createAction("router/push");

export const openReviewQuiz = createAction("open_review_quiz");
export const closeReviewQuiz = createAction("close_review_quiz");

export const removeModifierFromProduct = createAction("remove_modifier_from_product");
export const addModifierToProduct = createAction("add_modifier_to_product");