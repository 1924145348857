export const STORE_KEY = "application";

export const CITY = {
  DZYARZHYNSK: "dzyarzhynsk",
  LIDA: "lida",
};

export const CITY_URL = `/:city`;

export const CITIES = [
  {
    name: "Дзержинск",
    id: CITY.DZYARZHYNSK,
    phone: "+375296019891",
    phone_label: "+375 29 601-98-91",
    address: "г.Дзержинск, ул.Пераможцев 1Б пом.2",
    instagram_link: "https://www.instagram.com/svoe_cafe_dz/",
    vk_link: "https://vk.com/id630280591",
    location_map:
      "https://yandex.ru/map-widget/v1/?um=constructor%3A3b9d8a6a85b065ae5b3a1b92fc0863d972cb518a6455b428572cdbb760b71acd&amp;source=constructor",
    delivery_map:
      "https://yandex.ru/map-widget/v1/?um=constructor%3A269febae77e29f51e0b61ecfcfc2d187397d67225b7fe7f47ac80a117a2739c7&source=constructor",
    free_delivery_from: 40,
    google_maps_link: "https://goo.gl/maps/QytkN7eKe1x4c1iv6",
    yandex_maps_link: "https://yandex.eu/maps/-/CCUHRCc7sD",
  },
  {
    name: "Лида",
    id: CITY.LIDA,
    phone: "+375291880555",
    phone_label: "+375 29 188-05-55",
    address: "г.Лида, ул.Машерова 12",
    instagram_link: "https://www.instagram.com/svoe_cafe_lida/",
    location_map:
      "https://yandex.ru/map-widget/v1/?um=constructor%3A80f4e16df489edb5ed58258f9a2d15175148b156df07142a56dfa9aee86bb9d7&amp;source=constructor",
    delivery_map:
      "https://yandex.ru/map-widget/v1/?um=constructor%3A3db250fa691553f3bab96708ffc932430866f4ff9b56292345262cf412a32fca&amp;source=constructor",
    free_delivery_from: 35,
    google_maps_link: "https://goo.gl/maps/yFmfJ6Q99S9v6Jqu7",
  },
];

export const LOCAL_STORAGE_KEY = "svoecafe-city";

export const USER_PROPERTY_ID = {
  NAME: "name",
  PHONE: "phone",
  REVIEW: "review",
  PAY_TYPE: "pay_type",
  COMMENT: "comment",
  STREET: "street",
  HOUSE_NUMBER: "house_number",
  FLAT_NUMBER: "flat_number",
  FLOOR: "floor",
  ENTRANCE: "entrance",
  DELIVERY_TYPE: "delivery_type",
  BANKNOTE: "banknote",
};

export const PAY_TYPES = {
  CARD: "Card",
  CASH: "Cash",
};

export const PAY_TYPE_OPTIONS = [
  {
    value: PAY_TYPES.CARD,
    label: "Картой",
  },
  {
    value: PAY_TYPES.CASH,
    label: "Наличными",
  },
];

export const DELIVERY_TYPES = {
  SELF: "DeliveryByClient",
  DELIVERY: "DeliveryByCourier",
};
