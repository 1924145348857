import styled, { keyframes } from "styled-components";
import loader from "./images/loader.png";

export const Loading = styled.div`
  background-color: #fffff;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh + 200px);
`;

const loaderAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  width: 89px;
  height: 89px;
  background: url(${loader});
  margin-bottom: 33px;
  animation: ${loaderAnimation} 1.8s linear infinite;
`;
