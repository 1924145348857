import React from "react";
import * as Styled from "./styled";

export const ModalDialog = ({
  title = null,
  onClose,
  children,
  large = false,
}) => {
  return (
    <>
      <Styled.Wrapper onClick={onClose} />
      <Styled.Dialog large={large}>
        {title && <Styled.Title>{title}</Styled.Title>}
        <Styled.Content>{children}</Styled.Content>
      </Styled.Dialog>
    </>
  );
};
