import React from "react";
import { TextField } from "components/UI-Kit/TextField";
import * as Styled from "../styled";
import { STEP } from "../constants";

export const DirectorMailStep = ({
  pushNextStep,
  userName,
  userPhone,
  userReview,
  setUserProperty,
  submitReview,
}) => (
  <>
    <Styled.StepName>Письмо директору</Styled.StepName>
    <Styled.StepDescription>
      Расскажите нам, что вам не понравилось и что мы можем сделать лучше.
      Директор накажет виновных, свяжется с вами и предложит решение проблемы.
    </Styled.StepDescription>
    <Styled.FormWrapper>
      <Styled.FieldsWrapper>
        <TextField
          value={userName.value}
          setValue={(value) => setUserProperty({ id: userName.id, value })}
          placeholder="Имя*"
          error={userName.error}
        />
        <TextField
          value={userPhone.value}
          setValue={(value) => setUserProperty({ id: userPhone.id, value })}
          placeholder="Номер телефона*"
          error={userPhone.error}
          isPhoneField
        />
      </Styled.FieldsWrapper>
      <TextField
        value={userReview.value}
        setValue={(value) => setUserProperty({ id: userReview.id, value })}
        placeholder="Отзыв"
        error={userReview.error}
        rows={5}
        isTextArea
      />
      <Styled.Button
        onClick={() => {
          submitReview({ isMailToDirector: true });
          pushNextStep(STEP.THANKS_MAIL);
        }}
      >
        Отправить
      </Styled.Button>
    </Styled.FormWrapper>
  </>
);
