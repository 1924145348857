import createStoreConnector from './createStoreConnector';
import createApplicationStoreProvider from './createApplicationStoreProvider';
import createReducerInjector from './createReducerInjector';
import createSagaInjector from './createSagaInjector';
import createStoreCreator from './createStoreCreator';
import getStoreEnvOptions from './getStoreEnvOptions';

/**
 * Prepare utilities for creating store, injecting saga and etc. by storeKey
 *
 * @param {StoreEnvOptions} [rawStoreEnvOptions]
 * @returns {{ StoreProvider: Component, connectToStore: Function, injectReducer: injectReducer, injectSaga: injectSaga, createStore: Function }}
 */
const createStoreEnvironment = (rawStoreEnvOptions) => {
  const storeEnvOptions = getStoreEnvOptions(rawStoreEnvOptions);

  return {
    StoreProvider: createApplicationStoreProvider(storeEnvOptions),
    connectToStore: createStoreConnector(storeEnvOptions),

    injectReducer: ({ key, reducer }) =>
      createReducerInjector(storeEnvOptions)({ [key]: reducer }),
    injectMultipleReducers: createReducerInjector(storeEnvOptions),

    injectSaga: ({ key, saga, mode }) =>
      createSagaInjector(storeEnvOptions)({ [key]: { saga, mode } }),
    injectMultipleSagas: createSagaInjector(storeEnvOptions),

    createStore: createStoreCreator(storeEnvOptions),
    StoreContext: storeEnvOptions.context,
  };
};

export { createStoreEnvironment };
