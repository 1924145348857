import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import arrowIcon from "./images/arrow.svg";

export const CardWrapper = styled.div`
  padding: 10px;
  box-sizing: border-box;

  @media (max-width: 600px) {
    padding: 5px;
  }
`;

export const ProductCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(123, 122, 122, 0.1);
  border-radius: 30px;
  height: 100%;
`;

export const Name = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 10px;

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 17px;
  }
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 15px;
  }
`;

export const Image = styled(LazyLoadImage)`
  object-fit: cover;
  width: 100%;
  height: 200px;
  border-radius: 30px 30px 0px 0px;
  cursor: zoom-in;

  @media (max-width: 600px) {
    height: 150px;
  }
`;

export const Wrapper = styled.div`
  margin-top: 23px;
  padding: 0px 18px 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 245px);

  @media (max-width: 600px) {
    margin-top: 15px;
    height: calc(100% - 195px);
  }
`;

export const PriceAndOut = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Price = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;

  span {
    font-weight: 400;
    font-size: 18px;
  }

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 17px;

    span {
      font-size: 13px;
    }
  }
`;

export const Out = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.5);

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

export const Bottom = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    margin-top: 15px;
    flex-direction: column;
  }
`;

export const Modification = styled.select`
  width: 92px;
  height: 42px;
  border: 1px solid #f1eeee;
  box-sizing: border-box;
  border-radius: 15px;
  outline: none;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.5);
  padding-left: 17px;
  -webkit-appearance: none;
  background-image: url(${arrowIcon});
  background-repeat: no-repeat;
  background-position: calc(100% - 10px);
  background-color: #fff;

  @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const DoughtType = styled(Modification)`
  width: 100%;
  margin-bottom: -15px;
  margin-top: 10px;

  @media (max-width: 600px) {
    margin-bottom: -5px;
  }
`;

export const Button = styled.button`
  border: none;
  outline: none;
  background: #f9c646;
  border-radius: 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  width: 123px;
  height: 42px;
  cursor: pointer;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const ShowDescription = styled.button`
  background: #ffffff;
  border: 1px solid #f1eeee;
  box-sizing: border-box;
  border-radius: 15px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.5);
  padding: 9px 0px;
  cursor: pointer;
  margin: 20px 0px;
`;

export const BigImage = styled.img`
  border-radius: 10px;
  width: 100%;
`;

export const CheeseBoard = styled.div`
  border: 1px solid #f1eeee;
  box-sizing: border-box;
  border-radius: 15px;
  outline: none;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.5);
  padding-left: 17px;
  height: 42px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
`;