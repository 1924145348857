import { ModalDialog } from "components/ModalDialog";
import * as Styled from "./styled";
import { useState } from "react";
import { CITIES } from "containers/Application/constants";

export const ChooseCityDialog = ({ cityInfo, changeCity }) => {
  const [isOpenChooseCityDialog, setIsOpenChooseCityDialog] = useState(true);
  const [isChooseStep, setIsChooseStep] = useState(false);

  return (
    isOpenChooseCityDialog && (
      <ModalDialog>
        {isChooseStep ? (
          <div>
            <Styled.Title>Выбор города</Styled.Title>
            {CITIES.map((city) => (
              <Styled.City onClick={() => {
                changeCity({
                  target: {
                    value: city.id
                  }
                });

                setIsOpenChooseCityDialog(false);
              }}>
                <Styled.TopPanel>
                  <Styled.Point />
                  {city.name}
                </Styled.TopPanel>
                <Styled.Address>
                  {city.address}
                </Styled.Address>
              </Styled.City>
            ))}
          </div>
        ) : (
          <>
            <Styled.Image />
            <Styled.Title>Вы находитесь в городе {cityInfo.name}?</Styled.Title>
            <Styled.Actions>
              <Styled.Reject onClick={() => setIsChooseStep(true)}>
                Нет
              </Styled.Reject>
              <Styled.Accept onClick={() => setIsOpenChooseCityDialog(false)}>
                Да
              </Styled.Accept>
            </Styled.Actions>
          </>
        )}
      </ModalDialog>
    )
  );
};
