export const URLS = {
  MAIN: "/",
  CONTACTS: "/contacts",
  DELIVERY: "/dostavka",
  REVIEWS: "/otzyv",
  BASKET: "/basket",
  CHECKOUT: "/checkout",
  BIRTHDAY: "/birthday",
  SUCCESS: "/success",
  FAILURE: "/failure",
};

export const ORDER_STATUS = {
  SUCCESS: "success",
  FAILURE: "failure",
};

export const THANK_YOU_URL = `/:status`;