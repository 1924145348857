import React from "react";
import Carousel from "react-elastic-carousel";
import { connectToStore } from "store";
import { pushNextUrl as pushNextUrlAction } from "containers/Application/actions";
import { BANNERS } from "./constants";
import * as Styled from "./styled";

export const BannerComponent = ({ pushNextUrl, cityInfo }) => {
  const renderArrow = ({ type, onClick, isEdge }) => {
    if (BANNERS.length < 2) {
      return <></>;
    }

    return (
      <Styled.ArrowWrapper onClick={onClick}>
        <Styled.NextArrow reverse={type === "PREV"} isActive={!isEdge} />
      </Styled.ArrowWrapper>
    );
  };

  const renderPagination = ({ pages, activePage, onClick }) => {
    if (BANNERS.length < 2) {
      return <></>;
    }

    return (
      <Styled.Dots>
        {pages.map((page) => (
          <Styled.Dot
            key={page}
            isActive={activePage === page}
            onClick={() => onClick(page)}
          />
        ))}
      </Styled.Dots>
    );
  };

  return (
    <Styled.Banner>
      <Carousel
        itemsToShow={1}
        renderArrow={renderArrow}
        renderPagination={renderPagination}
        enableMouseSwipe={true}
      >
        {BANNERS.map(({ key, image, url }) => (
          <Styled.Slide
            key={key}
            src={image}
            draggable="false"
            onClick={() => {
              if (url) {
                pushNextUrl(`/${cityInfo.id}${url}`)
              }
            }}
          />
        ))}
      </Carousel>
    </Styled.Banner>
  );
};

export const Banner = connectToStore(null, {
  pushNextUrl: pushNextUrlAction,
})(BannerComponent);
