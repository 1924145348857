import styled from "styled-components";

export const Layout = styled.div`
  position: fixed;
  top: 0px;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
`;

export const SuccessTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

export const SuccessContent = styled(SuccessTitle)`
  font-weight: 400;
  margin-top: 10px;
`;

export const SuccessIcon = styled.img`
  width: 60px;
  margin: auto;
  display: block;
  margin-bottom: 20px;
`;