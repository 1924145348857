import styled from "styled-components";

export const Loading = styled.div``;

export const ProductsWrapper = styled.div`
  margin-top: 20px;
  width: calc(100% - 200px);

  @media (max-width: 1150px) {
    width: 100%;
    margin-top: 0px;
  }
`;

export const Basket = styled.div`
  background: #f9c646;
  border-radius: 15px;
  height: 61px;
  width: 166px;
  box-sizing: border-box;
  position: sticky;
  right: 60px;
  height: fit-content;
  top: 155px;
  margin-top: 136px;
  overflow: hidden;
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  @media (max-width: 1150px) {
    position: fixed;
    bottom: 20px;
    top: auto;
    border: 1px solid #000000;
  }

  @media (max-width: 960px) {
    right: 20px;
  }

  @media (max-width: 600px) {
    padding: 0px;
    width: fit-content;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
`;

export const Icon = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;

  @media (max-width: 600px) {
    width: 50px;
  }
`;

export const Line = styled.div`
  border-right: 1px solid #000000;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const Total = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;

  @media (max-width: 600px) {
    width: fit-content;
    padding-right: 10px;
  }
`;
