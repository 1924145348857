import styled, { css } from "styled-components";
import { Link as ScrollLink } from "react-scroll";

export const Wrapper = styled.div`
  margin-top: 40px;
  overflow-x: auto;
  overflow-y: hidden;
  position: sticky;
  height: 50px;
  box-sizing: border-box;
  ${({ isStickly }) => css`
    background: ${isStickly && "#ffffff"};
    box-shadow: ${isStickly && "0px 4px 14px rgb(123 122 122 / 10%)"};
  `}
  z-index: 9999;
  transition: all 0.2s ease;
  top: 87px;

  &::-webkit-scrollbar {
    width: 0;
  }

  @media (max-width: 1000px) {
    top: 61px;
  }

  @media (max-width: 600px) {
    top: 59px;
    margin-top: 13px;
  }
`;

export const Categories = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: auto;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  height: 50px;
  box-sizing: border-box;
  align-items: center;
  transition: all 0.2s;
`;

export const Category = styled(ScrollLink)`
  display: block;
  height: auto;
  cursor: pointer;
  padding-right: 20px;
  min-width: fit-content;
  color: ${(props) => (props.isActive ? "rgb(248,198,83)" : "#000000")};
  font-size: 19px;
  line-height: 24px;

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 17px;
  }
`;
