import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

  html {
    height: 100%;
  }

  body {
    height: 100%;
    margin: 0px;
    font-family: 'Rubik', sans-serif;
    background: #FDFDFD;
  }

  button, input, select {
    font-family: 'Rubik', sans-serif!important;
  }
  
`;
