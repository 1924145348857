import styled from "styled-components";

export const TitleL1 = styled.h2`
  font-weight: 500;
  font-size: 36px;
  line-height: 47px;
  color: #000000;
  margin: 0px;

  @media (max-width: 600px) {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
  }
`;
