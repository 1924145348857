const success = () => ({ isValid: true });

const failure = (error) => ({ isValid: false, message: error });

export const minLength = (min, error) => (value) => {
  if (value && value.length >= min) {
    return success();
  }

  return failure(error);
};

export const required = (error) => minLength(1, error);

export const maxLength = (max, error) => (value) => {
  if (value && value.length > max) {
    return failure(error);
  }

  return success();
};

export const maxLength64 = () => maxLength(
  64,
  "Максимально колличество допустимых символов 64"
);

export const matchRegex = (regex, error) => (value) => {
  if (value && regex.test(value)) {
    return success();
  }

  return failure(error);
};
