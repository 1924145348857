import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import getReducerInjectors from './getReducerInjectors';

/**
 * Create injector of reducer
 * @name createReducerInjector
 * @function
 *
 */
export default ({ context }) =>
  /**
   * Dynamically injects a reducer
   *
   * @name injectReducers
   * @function
   * @param {object} reducers - a key/value map of reducers, where `key` - a key of the reducer inside of store, `reducer` - a reducer that will be injected by key
   *
   */
  (reducers) => (WrappedComponent) => {
    class ReducerInjector extends React.Component {
      static displayName = `withReducer(${
        WrappedComponent.displayName || WrappedComponent.name || 'Component'
      })`;

      static WrappedComponent = WrappedComponent;

      static contextType = context;

      injectors = getReducerInjectors(this.context.store);

      componentWillMount() {
        const { injectReducers: injectReducersInternal } = this.injectors;

        injectReducersInternal(reducers);
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    return hoistNonReactStatics(ReducerInjector, WrappedComponent);
  };
