import React, { useEffect } from "react";
import { connectToStore } from "store";
import { createStructuredSelector } from "reselect";
import { cityInfoSelector } from "containers/Application/selectors";
import { Layout } from "components/Layout";
import { TitleL1 } from "components/UI-Kit/Titles";
import freeIcon from "./images/free.svg";
import payIcon from "./images/pay.svg";
import timeIcon from "./images/time.svg";
import * as Styled from "./styled";

export const DeliveryPageContainer = ({ cityInfo }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Layout>
        <Styled.Wrapper>
          <Styled.Block icon={timeIcon} width="30%">
            <Styled.Name>Время доставки</Styled.Name>
            <Styled.Content>
              Доставка осуществляется с 11.00 – до 23.00 ежедневно.
              <br />
              <br />
              Заказы принимаются с 11.00 – до 22.00 ежедневно.
              <br />
              <br />
              Время доставки 50 минут.
            </Styled.Content>
          </Styled.Block>
          <Styled.Block icon={freeIcon} width="40%">
            <Styled.Name>Бесплатная доставка</Styled.Name>
            <Styled.Content>
              Минимальная сумма заказа для бесплатной доставки составляет{" "}
              {cityInfo.free_delivery_from}
              рублей. <br />
              <br />
              При заказе на сумму менее {cityInfo.free_delivery_from} рублей,
              стоимость доставки составит 4 рубля.
            </Styled.Content>
          </Styled.Block>
          <Styled.Block icon={payIcon} width="30%">
            <Styled.Name>Оплата</Styled.Name>
            <Styled.Content>
              Оплата осуществляется наличными курьеру при получении заказа,
              также возможна оплата банковской картой.
            </Styled.Content>
          </Styled.Block>
        </Styled.Wrapper>
        <TitleL1>Зона доставки</TitleL1>
      </Layout>
      <Styled.Map title="delivery" src={cityInfo.delivery_map} />
    </>
  );
};

export const DeliveryPage = connectToStore(
  createStructuredSelector({
    cityInfo: cityInfoSelector,
  }),
  null
)(DeliveryPageContainer);
