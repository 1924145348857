import React from "react";
import * as Styled from "../styled";
import googleImage from "../images/google.png";
import yandexImage from "../images/yandex.png";
import websiteImage from "../images/website.png";
import { STEP } from "../constants";

export const ChoosePlaceStep = ({ pushNextStep, cityInfo }) => (
  <>
    <Styled.StepName>Оставить отзыв</Styled.StepName>
    <Styled.StepDescription>
      Мы рады, что вам все понравилось! Мы ценим ваше мнение и будем благодарны,
      если вы поделитесь им. Чтобы оставить отзыв, выберите один из сайтов ниже
      и нажмите на него.
    </Styled.StepDescription>
    <Styled.ReviewPlace href={cityInfo.google_maps_link} target="_blank">
      <img src={googleImage} alt="google" /> Google Карты
    </Styled.ReviewPlace>
    {cityInfo.yandex_maps_link && (
      <Styled.ReviewPlace href={cityInfo.yandex_maps_link} target="_blank">
        <img src={yandexImage} alt="yandex" /> Yandex Карты
      </Styled.ReviewPlace>
    )}
    <Styled.ReviewPlace onClick={() => pushNextStep(STEP.REVIEW_FORM)}>
      <img src={websiteImage} alt="website" />
      На сайте
    </Styled.ReviewPlace>
  </>
);
