import { connect } from 'react-redux';

/**
 * Creates store connector with using unique store key in context
 *
 * @name createStoreConnector
 * @function
 * @param {StoreEnvOptions} storeEnvOptions
 * @returns {Function}
 */
export default ({ context }) => (
  mapStateToProps,
  mapDispatchToProps = null,
  mergeProps = null,
  options = {},
) => (Component) =>
  connect(mapStateToProps, mapDispatchToProps, mergeProps, {
    ...options,
    context,
  })(Component);
