import conformsTo from 'lodash/conformsTo';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import invariant from 'invariant';

/**
 * Validate the shape of redux store
 *
 * @param {Store} store - a store object
 */
const checkStore = (store) => {
  const shape = {
    dispatch: isFunction,
    subscribe: isFunction,
    getState: isFunction,
    replaceReducer: isFunction,
    injectedReducers: isObject,
    injectedSagas: isObject,
    createReducer: isFunction,
  };

  invariant(
    conformsTo(store, shape),
    '[Store] injectors: Expected a valid redux store',
  );
};

export default checkStore;
