import styled from "styled-components";
import { ButtonWithActions as ButtonWithActionsComponent } from "components/UI-Kit/Buttons/ButtonWithActions";
import arrowIcon from "./images/arrow.svg";

export const ButtonWithActions = styled(ButtonWithActionsComponent)`
  @media (max-width: 600px) {
    width: 123px;
    font-size: 12px;
    width: 110px;
    height: 32px;
  }
`;

export const Wrapper = styled.div`
  margin-top: 50px;

  @media (max-width: 600px) {
    margin-top: 20px;
  }
`;

export const Basket = styled.div`
  margin-top: 52px;

  @media (max-width: 600px) {
    margin-top: 22px;
  }
`;

export const Product = styled.div`
  border-top: 1px solid #f1eeee;
  border-bottom: 1px solid #f1eeee;
  padding: 15px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 760px) {
    display: block;
  }
`;

export const Name = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 5px;

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 17px;
  }
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.5);

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 13px;
  }
`;

export const Image = styled.img`
  width: 77px;
  height: 77px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 28px;

  @media (max-width: 600px) {
    width: 60px;
    height: 60px;
    margin-right: 12px;
  }
`;

export const Delete = styled.img`
  cursor: pointer;
`;

export const ProductTotalPrice = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin: 0px 30px;
  width: 80px;

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 17px;
  }
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;

  @media (max-width: 760px) {
    justify-content: space-between;
    margin-left: 105px;
    margin-top: 20px;
  }

  @media (max-width: 600px) {
    justify-content: space-between;
    margin-top: 15px;
    margin-left: 0px;
  }
`;

export const ProductMain = styled.div`
  display: flex;
  align-items: center;
`;

export const Сurrency = styled.span`
  margin-left: 5px;
  font-weight: 100;
  font-size: 16px;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

export const BottomControls = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  align-items: center;

  @media (max-width: 600px) {
    align-items: flex-end;
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: flex-end;
    }
  }
`;

export const Return = styled.button`
  background-color: #f9c646;
  border-radius: 15px;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  width: 150px;
  height: 42px;
  position: relative;
  cursor: pointer;

  &:before {
    content: "";
    display: block;
    background-image: url(${arrowIcon});
    background-repeat: no-repeat;
    position: absolute;
    left: 17px;
    top: 15.5px;
    width: 11px;
    height: 11px;

    @media (max-width: 600px) {
      top: 13.5px;
      left: 14px;
    }
  }

  @media (max-width: 600px) {
    width: 130px;
    height: 38px;
  }
`;

export const Next = styled(Return)`
  &:before {
    left: calc(100% - 28px);
    transform: rotate(180deg);

    @media (max-width: 600px) {
      left: calc(100% - 25px);
    }
  }
`;

export const Total = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: baseline;

  ${ProductTotalPrice} {
    margin: 0px 34px 0px 10px;

    @media (max-width: 600px) {
      margin-right: 0px;
      margin-bottom: 15px;
    }
  }
`;

export const EmptyBasket = styled.div`
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 15px;
`;
