import styled, { css } from "styled-components";
import InputMask from "react-input-mask";

export const TextField = styled.div`
  position: relative;
  width: 100%;
`;

const FieldLabel = styled.div`
  position: absolute;
  pointer-events: none;
  transition: all 0.1s ease-out;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
  color: #808080;
  left: 25px;
`;

export const Label = styled(FieldLabel)`
  top: 16px;
  font-size: 16px;
  line-height: 21px;

  @media (max-width: 600px) {
    font-size: 14px;
    top: 13px;
  }
`;

const activeLabelCss = css`
  top: 5px;
  font-size: 12px;
  line-height: 18px;

  @media (max-width: 600px) {
    font-size: 10px;
  }
`;

export const ActiveLabel = styled(FieldLabel)`
  ${activeLabelCss};
`;

const inputcss = css`
  font-family: "Rubik", sans-serif !important;
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid ${({ error }) => (error ? "red" : "#f1eeee")};
  box-shadow: 0px 4px 14px rgba(123, 122, 122, 0.1);
  border-radius: 20px;
  padding: 22px 25px 10px;
  outline: none;
  font-size: 16px;

  &:focus + ${Label} {
    ${activeLabelCss};
  }

  @media (max-width: 600px) {
    font-size: 14px;
    padding: 20px 25px 8px;
  }
`;

export const Input = styled.input`
  ${inputcss}
`;

export const PhoneInput = styled(InputMask)`
  ${inputcss}
`;

export const TextArea = styled.textarea`
  ${inputcss};
  resize: none;
`;

export const Error = styled.div`
  font-size: 14px;
  color: red;
  margin-top: 5px;
`;
