export const STEP = {
  COMMON: "step1",
  CHOOSE_PLACE: "step2",
  DIRECTOR_MAIL: "step3",
  THANKS_REVIEW: "step4",
  THANKS_MAIL: "step5",
  REVIEW_FORM: "step6",
};

export const STEP_PROGRESS = {
  [STEP.COMMON]: 0,
  [STEP.CHOOSE_PLACE]: 0.3,
  [STEP.REVIEW_FORM]: 0.6,
  [STEP.THANKS_REVIEW]: 1,
  [STEP.DIRECTOR_MAIL]: 0.5,
  [STEP.THANKS_MAIL]: 1,
};
