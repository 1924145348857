import React, { useEffect } from "react";
import { compose } from "redux";
import {
  unstable_HistoryRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { injectReducer, injectSaga, connectToStore } from "store";
import { createStructuredSelector } from "reselect";
import { URLS, THANK_YOU_URL } from "appConstants";
import { Header } from "components/Header";
import { Footer } from "components/Footer";
import { MainPage } from "containers/MainPage";
import { ContactPage } from "containers/ContactPage";
import { DeliveryPage } from "containers/DeliveryPage";
import { ReviewsPage } from "containers/ReviewsPage";
import { BasketPage } from "containers/BasketPage";
import { CheckoutPage } from "containers/CheckoutPage";
import { BirthdayPage } from "containers/BirthdayPage";
import { ThankYouPage } from "containers/ThankYouPage";
import { history } from "./history";
import { isApplicationInitializedSelector } from "./selectors";
import { STORE_KEY, CITY_URL } from "./constants";
import { applicationReducer } from "./reducer";
import { applicationSagaWatcher } from "./saga";
import { startApplication as startApplicationAction } from "./actions";
import * as Styled from "./styled";

const ApplicationContainer = ({
  isApplicationInitialized,
  startApplication,
}) => {
  useEffect(() => {
    startApplication();
  }, [startApplication]);

  if (!isApplicationInitialized) {
    return (
      <Styled.Loading>
        <Styled.Loader />
        упс, загрузка...
      </Styled.Loading>
    );
  }

  return (
    <Styled.Wrapper>
      <Router history={history}>
        <div>
          <Header />
          <Routes>
            <Route
              exact
              path={`${CITY_URL}${URLS.MAIN}`}
              element={<MainPage />}
            />
            <Route
              path={`${CITY_URL}${URLS.CONTACTS}`}
              element={<ContactPage />}
            />
            <Route
              path={`${CITY_URL}${URLS.DELIVERY}`}
              element={<DeliveryPage />}
            />
            <Route
              path={`${CITY_URL}${URLS.REVIEWS}`}
              element={<ReviewsPage />}
            />
            <Route
              path={`${CITY_URL}${URLS.BASKET}`}
              element={<BasketPage />}
            />
            <Route
              path={`${CITY_URL}${URLS.CHECKOUT}`}
              element={<CheckoutPage />}
            />
            <Route
              path={`${CITY_URL}${URLS.BIRTHDAY}`}
              element={<BirthdayPage />}
            />
            <Route
              path={`${CITY_URL}${THANK_YOU_URL}`}
              element={<ThankYouPage />}
            />
          </Routes>
        </div>
        <Footer />
      </Router>
    </Styled.Wrapper>
  );
};

export const Application = compose(
  injectSaga({ key: STORE_KEY, saga: applicationSagaWatcher }),
  injectReducer({ key: STORE_KEY, reducer: applicationReducer }),
  connectToStore(
    createStructuredSelector({
      isApplicationInitialized: isApplicationInitializedSelector,
    }),
    {
      startApplication: startApplicationAction,
    }
  )
)(ApplicationContainer);
