import styled from "styled-components";
import pointIcon from "./images/location.svg";

export const Title = styled.div`
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  padding-bottom: 20px;
`;

export const Accept = styled.button`
  background-color: #f9c646;
  border-radius: 15px;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  width: 150px;
  height: 42px;
  position: relative;
  cursor: pointer;

  @media (max-width: 600px) {
    width: 130px;
    height: 38px;
  }
`;

export const Reject = styled(Accept)`
  border: 1px solid #f9c646;
  background-color: transparent;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const Image = styled.div`
  background: url(${pointIcon});
  background-size: cover;
  width: 50px;
  height: 56px;
  margin: auto;
  margin-bottom: 20px;
`;

export const Point = styled.div`
  background: url(${pointIcon});
  width: 18px;
  height: 19px;
`;

export const City = styled.div`
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 15px;
  padding: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  cursor: pointer;
  background-color: #fff;
  color: #000;
  margin: auto;
  margin-top: 16px;

    &:hover {
    border-color: #f2c75e;
  }
`;

export const TopPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: fit-content;
  font-weight: 500;
`;

export const Address = styled.div`
  color: rgba(0, 0, 0, 0.5);
  margin-top: 8px;
  font-size: 16px;
`;
