import { handleActions } from "redux-actions";
import {
  setIsInitialized,
  setCityInfo,
  loadMenu,
  addToBasket,
  removeFromBasket,
  totalRemoveFromBasket,
  setUserProperty,
  setLoading,
  validateProperties,
  openReviewQuiz,
  closeReviewQuiz,
  addModifierToProduct,
  removeModifierFromProduct,
} from "./actions";
import { ApplicationModel } from "./model";

export const applicationReducer = handleActions(
  {
    [setIsInitialized]: (state, { payload }) => state.setIsInitialized(payload),
    [setCityInfo]: (state, { payload }) => state.setCityInfo(payload),
    [loadMenu.recieve]: (state, { payload }) => state.setMenu(payload),
    [addToBasket]: (state, { payload }) => state.addToBasket(payload),
    [removeFromBasket]: (state, { payload }) => state.removeFromBasket(payload),
    [totalRemoveFromBasket]: (state, { payload }) =>
      state.totalRemoveFromBasket(payload),
    [setUserProperty]: (state, { payload }) => state.setUserProperty(payload),
    [setLoading]: (state, { payload }) => state.setLoading(payload),
    [validateProperties]: (state, { payload }) =>
      state.validateProperties(payload),
    [openReviewQuiz]: (state, { payload }) => state.openReviewQuiz(payload),
    [closeReviewQuiz]: (state, { payload }) => state.closeReviewQuiz(payload),
    [addModifierToProduct]: (state, { payload }) => state.addModifierToProduct(payload),
    [removeModifierFromProduct]: (state, { payload }) => state.removeModifierFromProduct(payload),
  },
  new ApplicationModel()
);
